export const generateRandomId = (len = 9) => {
  const maxLen = 10;
  if (len < 1) len = 1;
  if (len > maxLen) len = maxLen;
  return (
    "_" +
    Math.random()
      .toString(36)
      .substring(2, 2 + len)
  );
};
