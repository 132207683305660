<template>
  <div class="quote-preview" id="contentToDisplay">
    <div class="quote_header_contenitore">
      <div>
        <h1 class="infotronik_titolo_preventivo">
          {{ writeTitle }}
        </h1>
      </div>
      <div class="quote_preview_button_exporter_container" v-show="!IsLoading">
        <button
          id="quote_preview_button_exporter_pdf"
          class="quote_preview_button_exporter"
          @click="generatePDF"
        >
          PDF
        </button>
        <ExcelGenerator :text="'EXCEL'" :quote="this.quote" />
      </div>
    </div>
    <!-- SLIDES VISUALIZZAZIONE -->
    <div class="quote_preview_html_container" v-if="this.quote && !IsLoading">
      <!-- slide fisse -->
      <div class="quote_preview_fissa section_html">
        <img :src="prima_slide" alt="Slide 1" />
      </div>
      <div class="quote_preview_fissa section_html">
        <img :src="seconda_slide" alt="Slide 2" />
      </div>
      <div class="quote_preview_fissa section_html">
        <img :src="terza_slide" alt="Slide 3" />
      </div>
      <!-- post -->
      <div
        class="quote_preview_slide quote_preview_slide_postcontainer quote_preview_slide_bicolor quote_preview_slide_postconteiner section_html"
      >
        <div class="quote_preview_slide_post">
          <div class="quote_preview_slide_post_sx">
            <div class="quote_preview_slide_post_sx_post">
              <div class="quote_preview_slide_post_sx_post_titolocontent">
                <!-- scorro i tags -->
                <div
                  style="width: 100% !important"
                  v-for="(tag, tagIndex) in this.quote.tags"
                  :key="tagIndex"
                >
                  <!-- scorro gli editor nel tag -->
                  <div
                    style="width: 100% !important"
                    v-for="(editor, editorIndex) in tag.editors"
                    :key="editorIndex"
                  >
                    <!-- filtro editor scelto -->
                    <div
                      style="width: 100% !important"
                      v-if="
                        editor.editor.socialId ==
                        this.quote.postmockup.editor_id
                      "
                    >
                      <!-- intestazione mockup preventivo -->
                      <table
                        style="
                          width: 100% !important;
                          border-spacing: 0px !important;
                          box-sizing: border-box !important;
                        "
                      >
                        <body
                          style="
                            width: 100% !important;
                            border-spacing: 0px !important;
                            box-sizing: border-box !important;
                          "
                        >
                          <tr
                            style="
                              width: 100% !important;
                              border-spacing: 0px !important;
                              box-sizing: border-box !important;
                            "
                          >
                            <td class="quotes_preview_mockup_editorlogo;">
                              <div
                                class="quotes_preview_mockup_editorlogo_contimage"
                              >
                                <img
                                  :src="editor.base64Thumbnail"
                                  alt="Mockup Logo"
                                />
                              </div>
                            </td>
                            <td class="quotes_preview_mockup_editorpagina">
                              <!-- titolo -->
                              <p
                                class="quotes_preview_mockup_editorpagina_pagine"
                              >
                                <span>{{ editor.editor.name }} </span>
                                <img
                                  class="quotes_preview_mockup_editorpagina_pagine_v"
                                  :src="mockup_verificato"
                                  alt="Facebook Verificato"
                                />
                                con<br />
                                <span>{{ this.quote.postmockup.title }}</span
                                ><br />
                                Partnership pubblicizzata
                                <img
                                  class="quotes_preview_mockup_editorpagina_pagine_p"
                                  :src="mockup_pubblico"
                                  alt="Facebook Visibilità Pubblico"
                                />
                              </p>
                              <!-- fine titolo -->
                            </td>
                            <td class="quotes_preview_mockup_fisso">
                              <img
                                :src="mockup_pulsanti"
                                alt="Facebook Pulsanti"
                              />
                            </td>
                          </tr>
                        </body>
                      </table>
                      <!-- fine intestazione mockup preventivo -->
                    </div>
                    <!-- fine editor scelto -->
                  </div>
                  <!-- fine scorro gli editor nel tag -->
                </div>
                <!-- fine scorro i tags -->
                <p>{{ this.quote.postmockup.content }}</p>
              </div>
              <img
                v-if="this.quote.postmockup.postImageData"
                :src="this.quote.postmockup.postImageData"
                alt="Post Image"
              />
              <img v-else alt="Post Image" src="../css/img/placeholder.png" />
              <div class="quotes_preview_mockup_footer">
                <img :src="footer_post" alt="Post Image" />
              </div>
            </div>
          </div>
          <div class="quote_preview_slide_post_dx">
            <h3 class="quote_preview_slide_post_titolo">Il post</h3>
            <div
              v-html="sideTextFormatter"
              class="quote_preview_slide_post_testo"
            ></div>
          </div>
        </div>
      </div>
      <!-- fine post -->
      <!-- editori -->
      <div
        class="quote_preview_slide_tabs quote_preview_slide_bicolor section_html"
        style="width: 100% !important"
        v-for="(tag, tagIndex) in this.quote.tags"
        :key="tagIndex"
      >
        <div class="quote_preview_slide_tag">
          <div class="quote_preview_slide_tag_sx">
            <div class="quote_preview_slide_tag_desc">
              <div class="quote_preview_slide_tag_desc_titolo">
                Editori proposti:<br /><span
                  class="quote_preview_slide_tag_desc_titolo_tag"
                  >{{ tag.tag.name }}</span
                >
              </div>
              <div class="quote_preview_slide_tag_desc_desc">
                {{ tag.tagText }}
              </div>
            </div>
          </div>
          <div class="quote_preview_slide_tag_dx">
            <div class="quote_preview_slide_tag_dx_elenco">
              <!-- facebook ed instagram -->
              <table
                v-if="
                  this.quote.discriminators.includes('Facebook') &&
                  this.quote.discriminators.includes('Instagram')
                "
                class="quote_preview_slide_tag_dx_table"
              >
                <!-- riga fissa -->
                <tr class="quote_preview_slide_tag_dx_table_riga_title">
                  <td class="quote_preview_slide_tag_dx_table_blank">
                    Editors
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_titleimm">
                    <img :src="facebook_logo" alt="Facebook Logo" />
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_titleimm">
                    <img :src="instagram_logo" alt="Instagram Logo" />
                  </td>
                </tr>
                <!-- fine riga fissa -->
                <!-- righe variabili -->
                <tr
                  class="quote_preview_slide_tag_dx_table_riga"
                  v-for="(editor, editorIndex) in tag.editors"
                  :key="editorIndex"
                >
                  <td class="quote_preview_slide_tag_dx_table_cellaimm">
                    <span>
                      <i
                        v-if="editor.editor.isPremium"
                        class="lni lni-star"
                        style="color: #2cbeff"
                      ></i>
                    </span>
                    <img :src="editor.base64Thumbnail" alt="Editor logo" />
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_cellalink">
                    <a :href="editor.editor.link" target="_blank">{{
                      this.formatLink(editor.editor.link, editor.editor.name)
                    }}</a>
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_cellalink">
                    <a
                      v-if="editor.editor.instagram_Link"
                      :href="editor.editor.instagram_Link"
                      target="_blank"
                      >{{ editor.editor.instagram_Link }}</a
                    >
                    <span v-else>X</span>
                  </td>
                </tr>
                <!-- fine righe variabili -->
              </table>
              <!-- fine facebook ed instagram -->
              <!-- facebook  -->
              <table
                v-if="
                  this.quote.discriminators.includes('Facebook') &&
                  !this.quote.discriminators.includes('Instagram')
                "
                class="quote_preview_slide_tag_dx_table"
              >
                <!-- riga fissa -->
                <tr class="quote_preview_slide_tag_dx_table_riga_title">
                  <td class="quote_preview_slide_tag_dx_table_blank">
                    Editors
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_titleimm_fi">
                    <img :src="facebook_logo" alt="Facebook Logo" />
                  </td>
                </tr>
                <!-- fine riga fissa -->
                <!-- righe variabili -->
                <tr
                  class="quote_preview_slide_tag_dx_table_riga"
                  v-for="(editor, editorIndex) in tag.editors"
                  :key="editorIndex"
                >
                  <td class="quote_preview_slide_tag_dx_table_cellaimm">
                    <span>
                      <i
                        v-if="editor.editor.isPremium"
                        class="lni lni-star"
                        style="color: #2cbeff"
                      ></i>
                    </span>
                    <img :src="editor.base64Thumbnail" alt="Editor logo" />
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_cellalink_fi">
                    <a :href="editor.editor.link" target="_blank">{{
                      this.formatLink(editor.editor.link, editor.editor.name)
                    }}</a>
                  </td>
                </tr>
                <!-- fine righe variabili -->
              </table>
              <!-- fine facebook  -->
              <!-- instagram -->
              <table
                v-if="
                  !this.quote.discriminators.includes('Facebook') &&
                  this.quote.discriminators.includes('Instagram')
                "
                class="quote_preview_slide_tag_dx_table"
              >
                <!-- riga fissa -->
                <tr class="quote_preview_slide_tag_dx_table_riga_title">
                  <td class="quote_preview_slide_tag_dx_table_blank">
                    Editors
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_titleimm_fi">
                    <img :src="instagram_logo" alt="Instagram Logo" />
                  </td>
                </tr>
                <!-- fine riga fissa -->
                <!-- righe variabili -->
                <tr
                  class="quote_preview_slide_tag_dx_table_riga"
                  v-for="(editor, editorIndex) in tag.editors"
                  :key="editorIndex"
                >
                  <td class="quote_preview_slide_tag_dx_table_cellaimm">
                    <span>
                      <i
                        v-if="editor.editor.isPremium"
                        class="lni lni-star"
                        style="color: #2cbeff"
                      ></i>
                    </span>
                    <img :src="editor.base64Thumbnail" alt="Editor logo" />
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_cellalink_fi">
                    <a
                      v-if="editor.editor.instagram_Link"
                      :href="editor.editor.instagram_Link"
                      target="_blank"
                      >{{ editor.editor.instagram_Link }}</a
                    >
                    <span v-else>X</span>
                  </td>
                </tr>
                <!-- fine righe variabili -->
              </table>
              <!-- fine instagram -->
            </div>
          </div>
        </div>
      </div>
      <!-- fine editori  -->
      <!-- strategie  -->
      <div
        class="quote_preview_slide quote_preview_slide_monocolor section_html"
        v-for="(strategy, index) in this.quote.strategies"
        :key="index"
      >
        <div class="quote_preview_slide_strategy">
          <h4>{{ strategy.name }}</h4>
          <!-- video reach -->
          <div v-if="strategy.type === 'video_reach'">
            <p class="quote_preview_slide_strategy_pre">
              Raggiungere utenti in target, coinvolgendo diversi editori.<br />
              Seguono differenti pacchetti, a seconda del budget, con
              <strong>obiettivo reach</strong>
            </p>
            <table class="quotes_tabella_videoreach">
              <!-- riga fissa -->
              <tr class="quotes_tabella_videoreach_riga">
                <td class="quotes_tabella_videoreach_budgett">Budget</td>
                <td class="quotes_tabella_videoreach_npostt">N° post</td>
                <td class="quotes_tabella_videoreach_npostpt">
                  N° post premium
                </td>
                <td class="quotes_tabella_videoreach_reacht">Reach</td>
                <td class="quotes_tabella_videoreach_impt">Impressions</td>
              </tr>
              <!-- righe cavariabili -->
              <tr
                class="quotes_tabella_videoreach_riga"
                v-for="(goal, goalIndex) in strategy.goals"
                :key="goalIndex"
              >
                <td class="quotes_tabella_videoreach_budget">
                  {{ formatNumber(goal.budget) }} €
                </td>
                <td class="quotes_tabella_videoreach_npost">
                  {{ goal.posts }}
                </td>
                <td class="quotes_tabella_videoreach_npostp">
                  {{ goal.posts_premium }}
                </td>
                <td class="quotes_tabella_videoreach_reach">
                  {{ formatNumber(goal.reach) }}
                </td>
                <td class="quotes_tabella_videoreach_imp">
                  {{ formatNumber(goal.impressions) }}
                </td>
              </tr>
            </table>
          </div>
          <!-- fine video reach -->
          <!-- video view -->
          <div v-if="strategy.type === 'video_view'">
            <p class="quote_preview_slide_strategy_pre">
              Raggiungere utenti in target, coinvolgendo diversi editori.<br />
              Seguono differenti pacchetti, a seconda del budget, con
              <strong>obiettivo view</strong>
            </p>
            <table class="quotes_tabella_videoview">
              <!-- riga fissa -->
              <tr class="quotes_tabella_videoreach_riga">
                <td class="quotes_tabella_videoview_budgett">Budget</td>
                <td class="quotes_tabella_videoview_npostt">N° post</td>
                <td class="quotes_tabella_videoview_npostpt">
                  N° post premium
                </td>
                <td class="quotes_tabella_videoview_v3t">View 3"</td>
                <td class="quotes_tabella_videoview_v15t">View 15"</td>
                <td class="quotes_tabella_videview_impt">Impressions</td>
              </tr>
              <!-- valori -->
              <tr
                class="quotes_tabella_videoreach_riga"
                v-for="(goal, goalIndex) in strategy.goals"
                :key="goalIndex"
              >
                <td class="quotes_tabella_videoview_budget">
                  {{ formatNumber(goal.budget) }} €
                </td>
                <td class="quotes_tabella_videoview_npost">
                  {{ goal.posts }}
                </td>
                <td class="quotes_tabella_videoview_npostp">
                  {{ goal.posts_premium }}
                </td>
                <td class="quotes_tabella_videoview_v3">
                  {{ formatNumber(goal.views_3s) }}
                </td>
                <td class="quotes_tabella_videoview_v15">
                  {{ formatNumber(goal.views_15s) }}
                </td>
                <td class="quotes_tabella_videview_imp">
                  {{ formatNumLet(goal.impressions_min) }} -
                  {{ formatNumLet(goal.impressions_max) }}
                </td>
              </tr>
            </table>
          </div>
          <!-- fine video view -->
          <!-- foto reach -->
          <div v-if="strategy.type === 'image'">
            <p class="quote_preview_slide_strategy_pre">
              Raggiungere utenti in target, coinvolgendo diversi editori.<br />
              Seguono differenti pacchetti, a seconda del budget, con
              <strong>obiettivo reach</strong>
            </p>
            <table class="quotes_tabella_videoreach">
              <!-- riga fissa -->
              <tr class="quotes_tabella_videoreach_riga">
                <td class="quotes_tabella_videoreach_budgett">Budget</td>
                <td class="quotes_tabella_videoreach_npostt">N° post</td>
                <td class="quotes_tabella_videoreach_npostpt">
                  N° post premium
                </td>
                <td class="quotes_tabella_videoreach_reacht">Reach</td>
                <td class="quotes_tabella_videoreach_impt">Impressions</td>
              </tr>
              <!-- valori -->
              <tr
                class="quotes_tabella_videoreach_riga"
                v-for="(goal, goalIndex) in strategy.goals"
                :key="goalIndex"
              >
                <td class="quotes_tabella_videoreach_budget">
                  {{ formatNumber(goal.budget) }} €
                </td>
                <td class="quotes_tabella_videoreach_npost">
                  {{ goal.posts }}
                </td>
                <td class="quotes_tabella_videoreach_npostp">
                  {{ goal.posts_premium }}
                </td>
                <td class="quotes_tabella_videoreach_reach">
                  {{ formatNumber(goal.reach) }}
                </td>
                <td class="quotes_tabella_videoreach_imp">
                  {{ formatNumber(goal.impressions) }}
                </td>
              </tr>
            </table>
          </div>
          <!-- fine foto view -->
          <p class="quote_preview_slide_strategy_post">
            {{ strategy.footerText }}
          </p>
        </div>
      </div>
      <!-- fine strategie  -->
      <!-- slide fisse -->
      <div class="quote_preview_fissa section_html">
        <img :src="ultima_slide" alt="Slide Conclusiva" />
      </div>
      <!-- post -->
    </div>
    <!-- FINE SLIDES VISUALIZZAZIONE -->
    <!-- EXPORT PDF -->
    <div
      style="width: 100% !important; display: none !important"
      v-if="!IsLoading && this.quote"
      ref="contentToDisplay"
    >
      <!-- slide fisse -->
      <div
        class="quote_preview_fissa section_centrale primaslide_background_pdf section"
      >
        <img
          id="logo_small_bianco"
          :src="logo_small_bianco"
          alt="Logo Compatto Bianco"
        />
        <img
          id="logo_small_colorato"
          :src="logo_small_colorato"
          alt="Logo Compatto Colorato"
        />
        <img id="logo_colorato" :src="logo_colorato" alt="LogoColorato" />
      </div>
      <br />
      <br />
      <div class="quote_preview_fissa section_centrale section">
        <img :src="seconda_slide" alt="Slide 2" />
      </div>
      <br />
      <br />
      <div class="quote_preview_fissa section_centrale section">
        <img :src="terza_slide" alt="Slide 3" />
      </div>
      <!-- post -->
      <div
        class="quote_preview_slide quote_preview_slide_postcontainer quote_preview_slide_bicolor_pdf quote_preview_slide_postconteiner section_centrale section"
      >
        <div class="quote_preview_slide_post">
          <div class="quote_preview_slide_post_sx">
            <div class="quote_preview_slide_post_sx_post">
              <div class="quote_preview_slide_post_sx_post_titolocontent">
                <!-- scorro i tags -->
                <div
                  style="width: 100% !important"
                  v-for="(tag, tagIndex) in this.quote.tags"
                  :key="tagIndex"
                >
                  <!-- scorro gli editor nel tag -->
                  <div
                    style="width: 100% !important"
                    v-for="(editor, editorIndex) in tag.editors"
                    :key="editorIndex"
                  >
                    <!-- filtro editor scelto -->
                    <div
                      style="width: 100% !important"
                      v-if="
                        editor.editor.socialId ==
                        this.quote.postmockup.editor_id
                      "
                    >
                      <!-- intestazione mockup preventivo -->
                      <table
                        style="
                          width: 100% !important;
                          border-spacing: 0px !important;
                          box-sizing: border-box !important;
                        "
                      >
                        <body
                          style="
                            width: 100% !important;
                            border-spacing: 0px !important;
                            box-sizing: border-box !important;
                          "
                        >
                          <tr
                            style="
                              width: 100% !important;
                              border-spacing: 0px !important;
                              box-sizing: border-box !important;
                            "
                          >
                            <td class="quotes_preview_mockup_editorlogo;">
                              <div
                                class="quotes_preview_mockup_editorlogo_contimage"
                              >
                                <img
                                  :src="editor.base64Thumbnail"
                                  alt="Mockup Logo"
                                />
                              </div>
                            </td>
                            <td class="quotes_preview_mockup_editorpagina">
                              <!-- titolo -->
                              <p
                                class="quotes_preview_mockup_editorpagina_pagine"
                              >
                                <span>{{ editor.editor.name }} </span>
                                <img
                                  class="quotes_preview_mockup_editorpagina_pagine_v"
                                  :src="mockup_verificato"
                                  alt="Facebook Verificato"
                                />
                                con<br />
                                <span>{{ this.quote.postmockup.title }}</span
                                ><br />
                                Partnership pubblicizzata
                                <img
                                  class="quotes_preview_mockup_editorpagina_pagine_p"
                                  :src="mockup_pubblico"
                                  alt="Facebook Visibilità Pubblico"
                                />
                              </p>
                              <!-- fine titolo -->
                            </td>
                            <td class="quotes_preview_mockup_fisso">
                              <img
                                :src="mockup_pulsanti"
                                alt="Facebook Pulsanti"
                              />
                            </td>
                          </tr>
                        </body>
                      </table>
                      <!-- fine intestazione mockup preventivo -->
                    </div>
                    <!-- fine editor scelto -->
                  </div>
                  <!-- fine scorro gli editor nel tag -->
                </div>
                <!-- fine scorro i tags -->
                <p>{{ this.quote.postmockup.content }}</p>
              </div>
              <img
                v-if="this.quote.postmockup.postImageData"
                :src="this.quote.postmockup.postImageData"
                alt="Post Image"
              />
              <img v-else alt="Post Image" src="../css/img/placeholder.png" />
              <div class="quotes_preview_mockup_footer">
                <img :src="footer_post" alt="Post Image" />
              </div>
            </div>
          </div>
          <div class="quote_preview_slide_post_dx">
            <h3 class="quote_preview_slide_post_titolo">Il post</h3>
            <div
              v-html="sideTextFormatter"
              class="quote_preview_slide_post_testo"
            ></div>
          </div>
        </div>
      </div>
      <!-- fine post -->
      <!-- editori -->
      <div
        class="quote_preview_slide_tabs quote_preview_slide_bicolor_pdf section"
        style="width: 100% !important"
        v-for="(tag, tagIndex) in this.quote.tags"
        :key="tagIndex"
      >
        <div class="quote_preview_slide_tag">
          <div class="quote_preview_slide_tag_sx">
            <div class="quote_preview_slide_tag_desc">
              <div class="quote_preview_slide_tag_desc_titolo">
                Editori proposti:<br /><span
                  class="quote_preview_slide_tag_desc_titolo_tag"
                  >{{ tag.tag.name }}</span
                >
              </div>
              <div class="quote_preview_slide_tag_desc_desc">
                {{ tag.tagText }}
              </div>
            </div>
          </div>
          <div class="quote_preview_slide_tag_dx">
            <div class="quote_preview_slide_tag_dx_elenco">
              <!-- facebook ed instagram -->
              <table
                v-if="
                  this.quote.discriminators.includes('Facebook') &&
                  this.quote.discriminators.includes('Instagram')
                "
                class="quote_preview_slide_tag_dx_table"
              >
                <!-- riga fissa -->
                <tr class="quote_preview_slide_tag_dx_table_riga_title">
                  <td class="quote_preview_slide_tag_dx_table_blank">
                    Editors
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_titleimm">
                    <img :src="facebook_logo" alt="Facebook Logo" />
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_titleimm">
                    <img :src="instagram_logo" alt="Instagram Logo" />
                  </td>
                </tr>
                <!-- fine riga fissa -->
                <!-- righe variabili -->
                <tr
                  class="quote_preview_slide_tag_dx_table_riga"
                  v-for="(editor, editorIndex) in tag.editors"
                  :key="editorIndex"
                >
                  <td class="quote_preview_slide_tag_dx_table_cellaimm">
                    <span>
                      <i
                        v-if="editor.editor.isPremium"
                        class="lni lni-star"
                        style="color: #2cbeff"
                      ></i>
                    </span>
                    <img :src="editor.base64Thumbnail" alt="Editor logo" />
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_cellalink">
                    <a :href="editor.editor.link" target="_blank">{{
                      this.formatLink(editor.editor.link, editor.editor.name)
                    }}</a>
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_cellalink">
                    <a
                      v-if="editor.editor.instagram_Link"
                      :href="editor.editor.instagram_Link"
                      target="_blank"
                      >{{ editor.editor.instagram_Link }}</a
                    >
                    <span v-else>X</span>
                  </td>
                </tr>
                <!-- fine righe variabili -->
              </table>
              <!-- fine facebook ed instagram -->
              <!-- facebook  -->
              <table
                v-if="
                  this.quote.discriminators.includes('Facebook') &&
                  !this.quote.discriminators.includes('Instagram')
                "
                class="quote_preview_slide_tag_dx_table"
              >
                <!-- riga fissa -->
                <tr class="quote_preview_slide_tag_dx_table_riga_title">
                  <td class="quote_preview_slide_tag_dx_table_blank">
                    Editors
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_titleimm_fi">
                    <img :src="facebook_logo" alt="Facebook Logo" />
                  </td>
                </tr>
                <!-- fine riga fissa -->
                <!-- righe variabili -->
                <tr
                  class="quote_preview_slide_tag_dx_table_riga"
                  v-for="(editor, editorIndex) in tag.editors"
                  :key="editorIndex"
                >
                  <td class="quote_preview_slide_tag_dx_table_cellaimm">
                    <span>
                      <i
                        v-if="editor.editor.isPremium"
                        class="lni lni-star"
                        style="color: #2cbeff"
                      ></i>
                    </span>
                    <img :src="editor.base64Thumbnail" alt="Editor logo" />
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_cellalink_fi">
                    <a :href="editor.editor.link" target="_blank">{{
                      this.formatLink(editor.editor.link, editor.editor.name)
                    }}</a>
                  </td>
                </tr>
                <!-- fine righe variabili -->
              </table>
              <!-- fine facebook  -->
              <!-- instagram -->
              <table
                v-if="
                  !this.quote.discriminators.includes('Facebook') &&
                  this.quote.discriminators.includes('Instagram')
                "
                class="quote_preview_slide_tag_dx_table"
              >
                <!-- riga fissa -->
                <tr class="quote_preview_slide_tag_dx_table_riga_title">
                  <td class="quote_preview_slide_tag_dx_table_blank">
                    Editors
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_titleimm_fi">
                    <img :src="instagram_logo" alt="Instagram Logo" />
                  </td>
                </tr>
                <!-- fine riga fissa -->
                <!-- righe variabili -->
                <tr
                  class="quote_preview_slide_tag_dx_table_riga"
                  v-for="(editor, editorIndex) in tag.editors"
                  :key="editorIndex"
                >
                  <td class="quote_preview_slide_tag_dx_table_cellaimm">
                    <span>
                      <i
                        v-if="editor.editor.isPremium"
                        class="lni lni-star"
                        style="color: #2cbeff"
                      ></i>
                    </span>
                    <img :src="editor.base64Thumbnail" alt="Editor logo" />
                  </td>
                  <td class="quote_preview_slide_tag_dx_table_cellalink_fi">
                    <a
                      v-if="editor.editor.instagram_Link"
                      :href="editor.editor.instagram_Link"
                      target="_blank"
                      >{{ editor.editor.instagram_Link }}</a
                    >
                    <span v-else>X</span>
                  </td>
                </tr>
                <!-- fine righe variabili -->
              </table>
              <!-- fine instagram -->
            </div>
          </div>
        </div>
      </div>
      <!-- fine editori  -->
      <!-- strategie  -->
      <div
        class="quote_preview_slide quote_preview_slide_monocolor section"
        v-for="(strategy, index) in this.quote.strategies"
        :key="index"
      >
        <div class="quote_preview_slide_strategy">
          <h4>{{ strategy.name }}</h4>
          <!-- video reach -->
          <div v-if="strategy.type === 'video_reach'">
            <p class="quote_preview_slide_strategy_pre">
              Raggiungere utenti in target, coinvolgendo diversi editori.<br />
              Seguono differenti pacchetti, a seconda del budget, con
              <strong>obiettivo reach</strong>
            </p>
            <table class="quotes_tabella_videoreach">
              <!-- riga fissa -->
              <tr class="quotes_tabella_videoreach_riga">
                <td class="quotes_tabella_videoreach_budgett">Budget</td>
                <td class="quotes_tabella_videoreach_npostt">N° post</td>
                <td class="quotes_tabella_videoreach_npostpt">
                  N° post premium
                </td>
                <td class="quotes_tabella_videoreach_reacht">Reach</td>
                <td class="quotes_tabella_videoreach_impt">Impressions</td>
              </tr>
              <!-- righe cavariabili -->
              <tr
                class="quotes_tabella_videoreach_riga"
                v-for="(goal, goalIndex) in strategy.goals"
                :key="goalIndex"
              >
                <td class="quotes_tabella_videoreach_budget">
                  {{ formatNumber(goal.budget) }} €
                </td>
                <td class="quotes_tabella_videoreach_npost">
                  {{ goal.posts }}
                </td>
                <td class="quotes_tabella_videoreach_npostp">
                  {{ goal.posts_premium }}
                </td>
                <td class="quotes_tabella_videoreach_reach">
                  {{ formatNumber(goal.reach) }}
                </td>
                <td class="quotes_tabella_videoreach_imp">
                  {{ formatNumber(goal.impressions) }}
                </td>
              </tr>
            </table>
          </div>
          <!-- fine video reach -->
          <!-- video view -->
          <div v-if="strategy.type === 'video_view'">
            <p class="quote_preview_slide_strategy_pre">
              Raggiungere utenti in target, coinvolgendo diversi editori.<br />
              Seguono differenti pacchetti, a seconda del budget, con
              <strong>obiettivo view</strong>
            </p>
            <table class="quotes_tabella_videoview">
              <!-- riga fissa -->
              <tr class="quotes_tabella_videoreach_riga">
                <td class="quotes_tabella_videoview_budgett">Budget</td>
                <td class="quotes_tabella_videoview_npostt">N° post</td>
                <td class="quotes_tabella_videoview_npostpt">
                  N° post premium
                </td>
                <td class="quotes_tabella_videoview_v3t">View 3"</td>
                <td class="quotes_tabella_videoview_v15t">View 15"</td>
                <td class="quotes_tabella_videview_impt">Impressions</td>
              </tr>
              <!-- valori -->
              <tr
                class="quotes_tabella_videoreach_riga"
                v-for="(goal, goalIndex) in strategy.goals"
                :key="goalIndex"
              >
                <td class="quotes_tabella_videoview_budget">
                  {{ formatNumber(goal.budget) }} €
                </td>
                <td class="quotes_tabella_videoview_npost">
                  {{ goal.posts }}
                </td>
                <td class="quotes_tabella_videoview_npostp">
                  {{ goal.posts_premium }}
                </td>
                <td class="quotes_tabella_videoview_v3">
                  {{ formatNumber(goal.views_3s) }}
                </td>
                <td class="quotes_tabella_videoview_v15">
                  {{ formatNumber(goal.views_15s) }}
                </td>
                <td class="quotes_tabella_videview_imp">
                  {{ formatNumLet(goal.impressions_min) }} -
                  {{ formatNumLet(goal.impressions_max) }}
                </td>
              </tr>
            </table>
          </div>
          <!-- fine video view -->
          <!-- foto reach -->
          <div v-if="strategy.type === 'image'">
            <p class="quote_preview_slide_strategy_pre">
              Raggiungere utenti in target, coinvolgendo diversi editori.<br />
              Seguono differenti pacchetti, a seconda del budget, con
              <strong>obiettivo reach</strong>
            </p>
            <table class="quotes_tabella_videoreach">
              <!-- riga fissa -->
              <tr class="quotes_tabella_videoreach_riga">
                <td class="quotes_tabella_videoreach_budgett">Budget</td>
                <td class="quotes_tabella_videoreach_npostt">N° post</td>
                <td class="quotes_tabella_videoreach_npostpt">
                  N° post premium
                </td>
                <td class="quotes_tabella_videoreach_reacht">Reach</td>
                <td class="quotes_tabella_videoreach_impt">Impressions</td>
              </tr>
              <!-- valori -->
              <tr
                class="quotes_tabella_videoreach_riga"
                v-for="(goal, goalIndex) in strategy.goals"
                :key="goalIndex"
              >
                <td class="quotes_tabella_videoreach_budget">
                  {{ formatNumber(goal.budget) }} €
                </td>
                <td class="quotes_tabella_videoreach_npost">
                  {{ goal.posts }}
                </td>
                <td class="quotes_tabella_videoreach_npostp">
                  {{ goal.posts_premium }}
                </td>
                <td class="quotes_tabella_videoreach_reach">
                  {{ formatNumber(goal.reach) }}
                </td>
                <td class="quotes_tabella_videoreach_imp">
                  {{ formatNumber(goal.impressions) }}
                </td>
              </tr>
            </table>
          </div>
          <!-- fine foto view -->
          <p class="quote_preview_slide_strategy_post">
            {{ strategy.footerText }}
          </p>
        </div>
      </div>
      <!-- fine strategie  -->
      <!-- slide fisse -->
      <div
        class="quote_preview_fissa section_centrale primaslide_background_pdf section"
      >
        <!-- logo bianco in alto a sinistra -->
        <img
          id="logo_small_bianco"
          :src="logo_small_bianco"
          alt="Logo Compatto Bianco"
        />
        <!-- titolo -->
        <div id="ultima_slide_contenitore_titolo">
          <h1 id="ultima_slide_titolo">Grazie per l’attenzione.</h1>
          <br />
          <p id="ultima_slide_scritta">
            <span>Inflooendo Srl</span> - Via Secondo Cremonesi 4, 26900, Lodi
            (LO)
          </p>
        </div>
        <!-- indirizzi -->
        <div id="ultima_slide_contenitore_indirizzi">
          <table id="ultima_slide_tabella_indirizzi">
            <tr id="ultima_slide_tabella_indirizzi_riga">
              <td class="ultima_slide_tabella_indirizzi_cella">
                www.inflooendo.com
              </td>
              <td class="ultima_slide_tabella_indirizzi_cella">
                <img :src="icona_mail" alt="Icona Mail" /> info@inflooendo.com
              </td>
              <td class="ultima_slide_tabella_indirizzi_cella">
                <img :src="icona_tel" alt="Icona Telefono" /> +39 0371 564857
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- post -->
    </div>
    <!-- FINE EXPORT PDF -->
    <Spinner v-else-if="IsLoading" id="spinner" />
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import { ApiEndPoints } from "@/api/Endpoints";
import Spinner from "@/components/Spinner.vue";
import facebook_logo from "@/assets/quotes/facebook logo.png";
import instagram_logo from "@/assets/quotes/instagram logo.jpg";
import prima_slide from "@/assets/quotes/slide 1.jpg";
import seconda_slide from "@/assets/quotes/slide 2.jpg";
import terza_slide from "@/assets/quotes/slide 3.jpg";
import ultima_slide from "@/assets/quotes/ultima slide.jpg";
import mockup_verificato from "@/assets/quotes/mockup_verificato.png";
import mockup_pubblico from "@/assets/quotes/mockup_visual_globale.png";
import mockup_pulsanti from "@/assets/quotes/mockup_pulsanti.png";
import footer_post from "@/assets/quotes/footerpost.png";
import logo_small_bianco from "@/assets/quotes/logocompbianco.png";
import logo_small_colorato from "@/assets/quotes/logocompcolorato.png";
import logo_colorato from "@/assets/quotes/logocolorato.png";
import icona_mail from "@/assets/quotes/icona_mail.png";
import icona_tel from "@/assets/quotes/icona_tel.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ExcelGenerator from "@/components/quotes/ExcelGenerator_Preview.vue";

export default {
  components: {
    Spinner,
    ExcelGenerator,
  },
  name: "QuotePreview",
  data() {
    return {
      facebook_logo,
      instagram_logo,
      prima_slide,
      seconda_slide,
      terza_slide,
      ultima_slide,
      mockup_verificato,
      mockup_pubblico,
      mockup_pulsanti,
      footer_post,
      logo_small_bianco,
      logo_small_colorato,
      logo_colorato,
      icona_mail,
      icona_tel,
    };
  },
  computed: {
    writeTitle() {
      return this.quote
        ? (this.quote.campaign?.advertiser.userName ?? "") +
            "," +
            (this.quote.campaign?.name ?? "")
        : "Caricamento preventivo ...";
    },
    formattedQuote() {
      return JSON.stringify(this.quote, null, 2);
    },
    sideTextFormatter() {
      return this.quote.postmockup.sideText.replace(/\n/g, "<br>");
    },
  },
  created() {
    this.fetchQuote();
  },
  methods: {
    async fetchQuote() {
      this.IsLoading = false;
      const cod = this.$route.params.cod;
      if (!cod) {
        console.log("Codice preventivo non valido");
        return;
      }
      axios
        .get(`${ApiEndPoints.QUOTES}/${cod}`, {
          params: {
            includeEditorsThumbnails: true, // recupera i thumbs dei vari editor
          },
        })
        .then((response) => {
          this.quote = response.data;
          this.IsLoading = false;
        })
        .catch((error) => {
          console.error("Errore nel recupero del preventivo:", error);
        });
    },
    formatLink(link, name) {
      const lc_name = name.toLowerCase().replace(/\s+/g, "");
      const sanitizedLink = link.replace(/^https?:\/\//, ""); // Rimuove http:// o https://
      const linkParts = sanitizedLink.split("/");
      linkParts[linkParts.length - 1] = lc_name;
      return linkParts.join("/");
    },
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatNumLet(number) {
      if (number >= 1000000) {
        return (number / 1000000).toFixed(1) + " mil";
      } else if (number >= 1000) {
        return (number / 1000).toFixed(0) + " k";
      } else {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
  },
  setup() {
    const quote = ref(null);
    const IsLoading = ref(true);
    const contentToDisplay = ref(null);
    const generatePDF = async () => {
      if (contentToDisplay.value) {
        IsLoading.value = true;
        // Temporarily make the content visible
        contentToDisplay.value.style.display = "block";

        const sections = contentToDisplay.value.querySelectorAll(".section");
        const pdf = new jsPDF("landscape", "pt", "a4", true, 300);
        const pageWidth = pdf.internal.pageSize.getWidth();
        //const pageHeight = pdf.internal.pageSize.getHeight();

        //const pageHeightPx = pageHeight * (96 / 72);
        //const pageWidthPx = pageWidth * (96 / 72);

        //console.log(
        //  `Dimensioni della pagina: ${pageWidthPx} x ${pageHeightPx} pixel`
        //);

        const promises = Array.from(sections).map((section) =>
          html2canvas(section).then((canvas) => {
            const imgData = canvas.toDataURL("image/png", 1.0);
            const imgHeight = canvas.height;
            const imgWidth = canvas.width;

            //console.log(
            //  `Dimensioni della slide: ${imgWidth} x ${imgHeight} pixel`
            //);

            return { imgData, imgHeight, imgWidth };
          })
        );

        Promise.all(promises).then((images) => {
          images.forEach((image, index) => {
            if (index > 0) {
              pdf.addPage();
            }
            pdf.addImage(
              image.imgData,
              "PNG",
              0,
              0,
              pageWidth,
              (image.imgHeight * pageWidth) / image.imgWidth,
              undefined,
              "FAST"
            );
          });
          pdf.save(`${quote.value.cod}_${quote.value.campaign.name}.pdf`);
          IsLoading.value = false;

          // Hide the content again
          contentToDisplay.value.style.display = "none";
        });
      } else {
        console.error("contentToDisplay is not defined");
      }
    };

    return {
      contentToDisplay,
      generatePDF,
      quote,
      IsLoading,
    };
  },
};
</script>

<style scoped>
/* spinner */
#spinner {
  width: 1356px !important;
}
#spinner > .spinner-container {
  width: 1356px !important;
}
/* fine spinner */

.quote-preview {
  padding: 16px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-x: scroll !important;
  overflow-y: scroll !important;
  scrollbar-width: thin;
  scrollbar-color: #2cbeff #ffffff;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.quote-preview img {
  max-width: 100%;
  height: auto; /* Mantiene le proporzioni */
}

.quote_preview_html_container {
  width: 1356px !important;
  max-width: 1356px !important;
  min-width: 1356px !important;
  overflow-y: none !important;
  scrollbar-width: thin;
  scrollbar-color: #2cbeff #ffffff;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

pre {
  background-color: #fff;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: auto;
}

.quote_preview_fissa {
  padding: 0px !important;
  margin: 0px !important;
}
.quote_preview_slide {
  padding: 50px !important;
}
.quote_preview_slide_bicolor {
  background: linear-gradient(
    to right,
    #007bff 30%,
    #007bff 30%,
    #ffffff 30%,
    #ffffff 70%
  ) !important;
}
.quote_preview_slide_bicolor_pdf {
  background-image: url('../assets/quotes/sfondo_slide_bcolor.jpg'); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
}
.quote_preview_slide_monocolor {
  background: #007bff !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
/* header preventivatore */
.quote_header_contenitore {
  display: flex !important;
  justify-content: space-between !important;
}
/* pulsante pdf ed excel */
.quote_preview_button_exporter_container {
  position: fixed !important;
  top: 60px !important;
  right: 20px !important;
  z-index: 999 !important;
}
.quote_preview_button_exporter {
  font-size: 18px !important;
  height: 37px !important;
  cursor: pointer !important;
  font-family: "Bebas Neue", cursive !important;
  color: black !important;
  font-weight: normal !important;
  line-height: 18px !important;
  text-align: center !important;
  background-color: #2cbeff !important;
  border: none !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-right: 18px !important;
  padding-left: 18px !important;
  border: 1px solid white !important;
}
#quote_preview_button_exporter_pdf {
  margin-right: 5px !important;
}
.quote_preview_button_exporter:hover {
  color: white !important;
}
.section_html {
  margin-bottom: 20px !important;
  min-height: 762.75px !important;
}
/* titolo */
.infotronik_titolo_preventivo {
  color: #15465c !important;
  font-family: "Bebas Neue", cursive;
}
/* POST */
.quote_preview_slide_postcontainer {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  padding-left: 70px !important;
  padding-right: 70px !important;
}
.quote_preview_slide_postconteiner {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.quote_preview_slide_post {
  display: flex;
  width: 100%;
}
.quote_preview_slide_post_sx {
  flex: 1;
  width: 50%;
  padding: 60px !important;
}
.quote_preview_slide_post_sx_post {
  background: white;
  min-width: 400px !important;
  max-width: 400px !important;
  width: 400px !important;
  border-radius: 15px !important;
}
.quote_preview_slide_post_sx_post_titolocontent {
  padding: 10px;
}
.quote_preview_slide_post_sx_post_titolocontent > p {
  font-size: 13px !important;
  line-height: 1.5 !important;
  text-align: justify !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.quote_preview_slide_post_dx {
  flex: 1;
  width: 50%;
  padding: 40px !important;
}
/* titolo */
.quote_preview_slide_post_titolo {
  color: #007bff;
  padding-left: 30px !important;
  font-size: 25px;
}
/* testo desc post */
.quote_preview_slide_post_testo {
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding: 30px !important;
  padding-top: 0 !important;
  line-height: 2.5;
}
/* EDITORI */
.quote_preview_slide_tag {
  display: flex;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.quote_preview_slide_tag_sx {
  width: 30%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.quote_preview_slide_tag_dx {
  width: 70%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 60px;
  padding-bottom: 60px;
}
/* titolo editor tag */
.quote_preview_slide_tag_desc_titolo {
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding-left: 45px;
  padding-top: 60px;
}
.quote_preview_slide_tag_desc_titolo_tag {
  text-transform: uppercase !important;
}
/* testo editor tag */
.quote_preview_slide_tag_desc_desc {
  color: white;
  padding: 45px;
  padding-bottom: 60px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 12px;
}
/* tabella editor titolo */
.quote_preview_slide_tag_dx_table_title {
  width: 100% !important;
  border-collapse: collapse !important;
  margin-top: -1px !important;
}
.quote_preview_slide_tag_dx_table_riga_title {
  width: 100% !important;
}
.quote_preview_slide_tag_dx_table_blank {
  width: 20% !important;
  max-width: 20% !important;
  min-width: 20% !important;
  border: 2px solid #bbbbbb;
  text-align: center !important;
  color: white !important;
}
.quote_preview_slide_tag_dx_table_titleimm {
  width: 40% !important;
  max-width: 40% !important;
  min-width: 40% !important;
  border: 2px solid #bbbbbb;
  text-align: center !important;
}
.quote_preview_slide_tag_dx_table_titleimm_fi {
  width: 80% !important;
  max-width: 80% !important;
  min-width: 80% !important;
  border: 2px solid #bbbbbb;
  text-align: center !important;
}
.quote_preview_slide_tag_dx_table_titleimm > img {
  max-width: 35px !important;
}
.quote_preview_slide_tag_dx_table_titleimm_fi > img {
  max-width: 35px !important;
}
/* tabella editor */
.quote_preview_slide_tag_dx_table {
  width: 100% !important;
  border-collapse: collapse !important;
  margin-top: -1px !important;
}
.quote_preview_slide_tag_dx_table_riga {
  width: 100% !important;
}
.quote_preview_slide_tag_dx_table_cellaimm {
  width: 20% !important;
  max-width: 20% !important;
  min-width: 20% !important;
  border: 2px solid #bbbbbb;
  text-align: center !important;
  display: table-cell !important;
  vertical-align: middle !important;
}
.quote_preview_slide_tag_dx_table_cellaimm > img {
  max-width: 35px !important;
  display: inline-block !important;
  vertical-align: middle !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.quote_preview_slide_tag_dx_table_cellaimm > span {
  display: inline-block !important;
  vertical-align: middle !important;
  margin-right: 5px !important;
}
.quote_preview_slide_tag_dx_table_cellalink {
  width: 40% !important;
  max-width: 40% !important;
  min-width: 40% !important;
  border: 2px solid #bbbbbb;
  padding-right: 15px !important;
  padding-left: 15px !important;
  text-align: center !important;
}
.quote_preview_slide_tag_dx_table_cellalink_fi {
  width: 80% !important;
  max-width: 80% !important;
  min-width: 80% !important;
  border: 2px solid #bbbbbb;
  padding-right: 15px !important;
  padding-left: 15px !important;
  text-align: center !important;
}
.quote_preview_slide_tag_dx_table_cellalink > a {
  color: #007bff !important;
  font-size: 13px !important;
  font-weight: 700 !important;
}
.quote_preview_slide_tag_dx_table_cellalink > span {
  font-weight: bold !important;
  color: #007bff !important;
}
.quote_preview_slide_tag_dx_table_cellalink_fi > a {
  color: #007bff !important;
  font-size: 13px !important;
  font-weight: 700 !important;
}
.quote_preview_slide_tag_dx_table_cellalink_fi > span {
  color: #007bff !important;
  font-weight: bold !important;
}
/* STRATEGIE */
.quote_preview_slide_strategy {
  width: 100% !important;
  padding-top: 30px;
  padding-bottom: 30px;
}
/* titolo */
.quote_preview_slide_strategy > h4 {
  font-size: 40px !important;
  color: white !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
/* pre tabella */
.quote_preview_slide_strategy_pre {
  color: white !important;
  margin-bottom: 35px !important;
  margin-top: 35px !important;
  line-height: 1.9 !important;
  font-size: 14px !important;
}
/* post tabella */
.quote_preview_slide_strategy_post {
  color: white !important;
  font-style: italic !important;
  margin-bottom: 0px !important;
  margin-top: 35px !important;
  line-height: 1.4 !important;
  font-size: 14px !important;
}
/* tabella video reach */
.quotes_tabella_videoreach {
  width: 100% !important;
  border-collapse: collapse !important;
  margin-top: -1px !important;
}
/* titoli */
.quotes_tabella_videoreach_budgett {
  width: calc(100% / 5) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
.quotes_tabella_videoreach_npostt {
  width: calc(100% / 5) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
.quotes_tabella_videoreach_npostpt {
  width: calc(100% / 5) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
.quotes_tabella_videoreach_reacht {
  width: calc(100% / 5) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
.quotes_tabella_videoreach_impt {
  width: calc(100% / 5) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
/* valori */
.quotes_tabella_videoreach_budget {
  width: calc(100% / 5) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
}
.quotes_tabella_videoreach_npost {
  width: calc(100% / 5) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
}
.quotes_tabella_videoreach_npostp {
  width: calc(100% / 5) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
  font-size: 20px !important;
}
.quotes_tabella_videoreach_reach {
  width: calc(100% / 5) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
}
.quotes_tabella_videoreach_imp {
  width: calc(100% / 5) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
}
/* tabella video view */
.quotes_tabella_videoview {
  width: 100% !important;
  border-collapse: collapse !important;
  margin-top: -1px !important;
}
.quotes_tabella_videview_impt {
  width: calc(100% / 6) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
.quotes_tabella_videview_imp {
  width: calc(100% / 6) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
}
.quotes_tabella_videoview_v3t {
  width: calc(100% / 6) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
.quotes_tabella_videoview_v3 {
  width: calc(100% / 6) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
}
.quotes_tabella_videoview_v15t {
  width: calc(100% / 6) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
.quotes_tabella_videoview_v15 {
  width: calc(100% / 6) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
}
.quotes_tabella_videoview_npostpt {
  width: calc(100% / 6) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
.quotes_tabella_videoview_npostp {
  width: calc(100% / 6) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
}
.quotes_tabella_videoview_npostt {
  width: calc(100% / 6) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
.quotes_tabella_videoview_npost {
  width: calc(100% / 6) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
}
.quotes_tabella_videoview_budgett {
  width: calc(100% / 6) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
.quotes_tabella_videoview_budget {
  width: calc(100% / 6) !important;
  color: white !important;
  border: 2px solid white;
  text-align: center !important;
  font-size: 20px !important;
}
.quotes_tabella_videoreach > tbody > tr > td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.quotes_tabella_videoview > tbody > tr > td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.quotes_tabella_videoreach > tr > td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.quotes_tabella_videoview > tr > td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
/* INTESTAZIONE MOCKUP */
/* logo */
.quotes_preview_mockup_editorlogo {
  width: 40px !important;
  max-width: 40px !important;
  min-width: 40px !important;
  text-align: left !important;
}
.quotes_preview_mockup_editorlogo_contimage {
  width: 39px !important;
  height: 39px !important;
  border-radius: 50% !important;
  overflow: hidden !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 2px solid grey !important;
  margin: 0 !important;
}
.quotes_preview_mockup_editorlogo_contimage > img {
  width: 100% !important;
  height: auto !important;
}
/* pagine */
.quotes_preview_mockup_editorpagina {
  width: 270px !important;
  max-width: 270px !important;
  min-width: 270px !important;
  vertical-align: top;
  text-align: left !important;
}
.quotes_preview_mockup_editorpagina_pagine {
  color: grey !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  margin-top: 0px !important;
  margin-left: 12px !important;
}
.quotes_preview_mockup_editorpagina_pagine > span {
  color: black !important;
  font-weight: bold !important;
  font-size: 13px !important;
}
.quotes_preview_mockup_editorpagina_pagine_v {
  height: 12px !important;
  max-height: 12px !important;
  margin-left: 3px !important;
}
.quotes_preview_mockup_editorpagina_pagine_p {
  height: 12px !important;
  max-height: 12px !important;
  margin-left: 2px !important;
}
/* pulsanti */
.quotes_preview_mockup_fisso {
  width: 61px !important;
  max-width: 61px !important;
  min-width: 61px !important;
  text-align: right !important;
}
/* footer */
.quotes_preview_mockup_footer {
  text-align: center !important;
}
.quotes_preview_mockup_footer > img {
  width: 352px !important;
}
/* TEMPLATE PDF */
.section {
  width: 1123px !important;
  max-width: 1123px !important;
  min-width: 1123px !important;
  height: 794px !important;
  max-height: 794px !important;
  min-height: 794px !important;
  padding: 0px !important;
}
.section_centrale {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
/* sfondo dinamico */
.primaslide_background_pdf {
  position: relative !important;
  background-image: url("~@/assets/quotes/Background 2.png") !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  overflow: hidden !important;
}
/* logo bianco compatto dinamico */
#logo_small_bianco {
  position: absolute !important;
  height: 90px !important;
  width: auto !important;
  top: 0px !important;
  left: 10px !important;
  z-index: 100 !important;
}
/* logo colorato */
#logo_small_colorato {
  position: absolute !important;
  height: auto !important;
  width: 1123px !important;
  top: -28px !important;
  left: 248px !important;
  opacity: 0.1 !important;
}
/* logo bianco compatto dinamico */
#logo_colorato {
  position: absolute !important;
  height: 60px !important;
  width: auto !important;
  top: 55% !important;
  transform: translateY(-55%) !important;
  left: 75px !important;
  z-index: 100 !important;
}
/* titolo */
#ultima_slide_contenitore_titolo {
  position: absolute !important;
  top: 55% !important;
  transform: translateY(-55%) !important;
  left: 75px !important;
  z-index: 100 !important;
}
#ultima_slide_titolo {
  color: white !important;
  font-size: 50px !important;
  font-weight: 200 !important;
  margin-bottom: 0px !important;
}
#ultima_slide_scritta {
  color: white !important;
  font-weight: 200 !important;
  margin-top: 2px !important;
}
#ultima_slide_scritta > span {
  color: white !important;
  font-weight: 800 !important;
}
/* indizzi */
#ultima_slide_contenitore_indirizzi {
  position: absolute !important;
  width: 1200px !important;
  left: 60px !important;
  bottom: 50px !important;
}
#ultima_slide_tabella_indirizzi {
  width: 1200px !important;
}
.ultima_slide_tabella_indirizzi_cella {
  color: white !important;
  font-weight: 800 !important;
}
.ultima_slide_tabella_indirizzi_cella > img {
  vertical-align: middle;
}
/* post */
.section > .quote_preview_slide_post {
  width: 1123px !important;
  max-width: 1123px !important;
  min-width: 1123px !important;
  height: 794px !important;
  max-height: 794px !important;
  min-height: 794px !important;
}
.section > .quote_preview_slide_post > .quote_preview_slide_post_sx {
  height: 794px !important;
  max-height: 794px !important;
  min-height: 794px !important;
  padding: 0px !important;
  padding-left: 60px !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.section > .quote_preview_slide_post > .quote_preview_slide_post_dx {
  height: 794px !important;
  max-height: 794px !important;
  min-height: 794px !important;
  padding: 0px !important;
  padding-right: 30px !important;
  padding-left: 30px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: center !important;
}
.section
  > .quote_preview_slide_post
  > .quote_preview_slide_post_dx
  > .quote_preview_slide_post_testo {
  word-wrap: break-word !important;
  padding-left: 30px !important;
  padding-right: 60px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 1.8 !important;
  font-size: 13px !important;
}
/* dimensioni post */
.section
  > .quote_preview_slide_post
  > .quote_preview_slide_post_sx
  > .quote_preview_slide_post_sx_post {
  min-width: 340px !important;
  max-width: 340px !important;
  width: 340px !important;
  border-radius: 14px !important;
}
.section
  > .quote_preview_slide_post
  > .quote_preview_slide_post_sx
  > .quote_preview_slide_post_sx_post
  > .quotes_preview_mockup_footer
  > img {
  width: 310px !important;
}
.section
  > .quote_preview_slide_post
  > .quote_preview_slide_post_sx
  > .quote_preview_slide_post_sx_post
  > .quote_preview_slide_post_sx_post_titolocontent {
  padding: 7px !important;
}
.section
  > .quote_preview_slide_post
  > .quote_preview_slide_post_sx
  > .quote_preview_slide_post_sx_post
  > .quote_preview_slide_post_sx_post_titolocontent
  > p {
  font-size: 12px !important;
  line-height: 1.2 !important;
  font-weight: 400 !important;
}
.section .quotes_preview_mockup_editorlogo_contimage {
  width: 38px !important;
  height: 38px !important;
}
.section
  > .quote_preview_slide_post
  > .quote_preview_slide_post_sx
  > .quote_preview_slide_post_sx_post
  > .quote_preview_slide_post_sx_post_titolocontent
  > div
  > div
  > div
  > table
  > body
  > tr
  > .quotes_preview_mockup_editorpagina {
  width: 220px !important;
  max-width: 220px !important;
  min-width: 220px !important;
}
.section
  > .quote_preview_slide_post
  > .quote_preview_slide_post_sx
  > .quote_preview_slide_post_sx_post
  > .quote_preview_slide_post_sx_post_titolocontent
  > div
  > div
  > div
  > table
  > body
  > tr
  > .quotes_preview_mockup_editorpagina
  > .quotes_preview_mockup_editorpagina_pagine {
  font-size: 10px !important;
  margin-top: 2px !important;
  margin-left: 5px !important;
  line-height: 13px !important;
  margin-bottom: 5px !important;
}
.section
  > .quote_preview_slide_post
  > .quote_preview_slide_post_sx
  > .quote_preview_slide_post_sx_post
  > .quote_preview_slide_post_sx_post_titolocontent
  > div
  > div
  > div
  > table
  > body
  > tr
  > .quotes_preview_mockup_editorpagina
  > .quotes_preview_mockup_editorpagina_pagine
  > span {
  font-size: 11px !important;
  margin-left: 0px !important;
}
.section
  > .quote_preview_slide_post
  > .quote_preview_slide_post_sx
  > .quote_preview_slide_post_sx_post
  > .quote_preview_slide_post_sx_post_titolocontent
  > div
  > div
  > div
  > table
  > body
  > tr
  > .quotes_preview_mockup_editorpagina
  > .quotes_preview_mockup_editorpagina_pagine
  > .quotes_preview_mockup_editorpagina_pagine_v {
  height: 10px !important;
  max-height: 10px !important;
  margin-left: 2px !important;
}
.section
  > .quote_preview_slide_post
  > .quote_preview_slide_post_sx
  > .quote_preview_slide_post_sx_post
  > .quote_preview_slide_post_sx_post_titolocontent
  > div
  > div
  > div
  > table
  > body
  > tr
  > .quotes_preview_mockup_editorpagina
  > .quotes_preview_mockup_editorpagina_pagine
  > .quotes_preview_mockup_editorpagina_pagine_p {
  height: 10px !important;
  max-height: 10px !important;
  margin-left: 0px !important;
}
.section
  > .quote_preview_slide_post
  > .quote_preview_slide_post_sx
  > .quote_preview_slide_post_sx_post
  > .quote_preview_slide_post_sx_post_titolocontent
  > div
  > div
  > div
  > table
  > body
  > tr
  > .quotes_preview_mockup_fisso {
  width: 59px !important;
  max-width: 59px !important;
  min-width: 59px !important;
}
.section
  > .quote_preview_slide_post
  > .quote_preview_slide_post_sx
  > .quote_preview_slide_post_sx_post
  > .quote_preview_slide_post_sx_post_titolocontent
  > div
  > div
  > div
  > table
  > body
  > tr
  > .quotes_preview_mockup_fisso {
  margin-bottom: 5px !important;
}
/* editori */
.section
  > .quote_preview_slide_tag
  > .quote_preview_slide_tag_sx
  > .quote_preview_slide_tag_desc
  > .quote_preview_slide_tag_desc_titolo {
  padding-left: 30px !important;
  padding-top: 30px !important;
}
.section
  > .quote_preview_slide_tag
  > .quote_preview_slide_tag_sx
  > .quote_preview_slide_tag_desc
  > .quote_preview_slide_tag_desc_desc {
  padding: 30px !important;
  padding-bottom: 30px !important;
}
.section > .quote_preview_slide_tag > .quote_preview_slide_tag_dx {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
/* strategia */
.section > .quote_preview_slide_strategy {
  padding: 50px !important;
  width: auto !important;
}
/* FINE TEMPLATE PDF */
</style>
