<template>
  <div class="inflooad_post_specs">
    <div class="inflooad_postdetails_firstrow">
      <div class="inflooad_postdetails_contenttitle">
        <label
          class="bebas inflooad_postdetails_title"
          for="strategy-details"
          >{{ localPost.editor.name }}</label
        >
        <div
          v-if="localPost.text && localPost.text.trim() !== ''"
          class="inflooad_postdetails_selectedtext"
        >
          {{ localPost.text }}
        </div>
      </div>
      <img
        class="inflooad_postdetails_selectedimage"
        v-if="localPost.image"
        :src="localPost.image"
        alt="Contenuto Post"
      />
      <img
        class="inflooad_postdetails_selectedimage"
        v-else
        src="../../css/img/placeholder.png"
        alt="Contenuto Placeholder"
      />
    </div>
    <br />
    <div class="inflooad_post_specs_tags">
      <label class="inflooad_post_specs_tags_title">Tags</label>
      <br />
      <div class="inflooad_post_specs_tags_list">
        <span
          v-for="socialTag in localPost.editor.socialTags"
          :key="socialTag.tagId"
        >
          {{ socialTag.tag.name }}
        </span>
      </div>
    </div>
    <div class="inflooad_post_specs_pricetype">
      <label class="inflooad_post_specs_tags_title">Price Type:</label>
      <div>
        <label class="inflooad_post_specs_pricetype_sel">
          <input type="radio" value="Fixed" v-model="localPost.priceType" />
          Fixed
        </label>
        <label class="inflooad_post_specs_pricetype_sel">
          <input type="radio" value="Variable" v-model="localPost.priceType" />
          Variable
        </label>
      </div>
    </div>
    <div v-if="localPost.priceType === 'Fixed'">
      <label class="inflooad_post_specs_tariffa_label">Price: €</label>
      <input
        class="inflooad_post_specs_tariffa"
        type="number"
        v-model="localPost.price"
      />
    </div>
    <div v-if="localPost.priceType === 'Variable'">
      <label class="inflooad_post_specs_tariffa_label">InPage Price: €</label>
      <input
        class="inflooad_post_specs_tariffa"
        type="number"
        v-model="localPost.organicCoveragePrice"
      />
      <br />
      <label class="inflooad_post_specs_tariffa_label">Ghost Price: €</label>
      <input
        class="inflooad_post_specs_tariffa"
        type="number"
        v-model="localPost.sponsoredCoveragePrice"
      />
    </div>
    <div class="inflooad_post_specs_budgettotale">
      <label class="inflooad_post_specs_tariffa_label">Budget:</label>
      <input
        class="inflooad_post_specs_tariffa"
        type="number"
        v-model="localPost.budget"
      />
    </div>
    <div class="inflooad_post_specs_gender">
      <label class="inflooad_post_specs_tariffa_label">Obiettivo:</label>
      <div
        class="flex-container flex-row flex-wrap overflow-auto innovativestudio_contenitore_sesso inflooad_genderage_select_block"
      >
        <div class="flex-container flex-row align-center ns pnt">
          <input
            type="checkbox"
            id="copertura"
            value="copertura"
            v-model="selectedOptions"
            checked
          />
          <label class="inflooad_post_specs_genderage_select" for="copertura"
            >Copertura</label
          >
        </div>
        <div class="flex-container flex-row align-center ns pnt">
          <input
            type="checkbox"
            id="interazioni"
            value="interazioni"
            v-model="selectedOptions"
          />
          <label class="inflooad_post_specs_genderage_select" for="interazioni"
            >Interazioni</label
          >
        </div>
        <div class="flex-container flex-row align-center ns pnt">
          <input
            type="checkbox"
            id="traffico"
            value="traffico"
            v-model="selectedOptions"
          />
          <label class="inflooad_post_specs_genderage_select" for="traffico"
            >Traffico</label
          >
        </div>
      </div>
    </div>
    <div class="inflooad_post_specs_gender">
      <label class="inflooad_post_specs_tariffa_label">Gender:</label>
      <div
        class="flex-container flex-row flex-wrap overflow-auto innovativestudio_contenitore_sesso inflooad_genderage_select_block"
      >
        <div class="flex-container flex-row align-center ns pnt">
          <input
            type="checkbox"
            id="all-genders"
            value="all"
            v-model="localPost.target.gender"
            @change="onAllGendersChange"
          />
          <label class="inflooad_post_specs_genderage_select" for="all-genders"
            >Tutti i generi</label
          >
        </div>
        <div class="flex-container flex-row align-center ns pnt">
          <input
            type="checkbox"
            id="female"
            value="female"
            v-model="localPost.target.gender"
            :disabled="localPost.target.gender.includes('all')"
          />
          <label class="inflooad_post_specs_genderage_select" for="female"
            >Donne</label
          >
        </div>
        <div class="flex-container flex-row align-center ns pnt">
          <input
            type="checkbox"
            id="male"
            value="male"
            v-model="localPost.target.gender"
            :disabled="localPost.target.gender.includes('all')"
          />
          <label class="inflooad_post_specs_genderage_select" for="male"
            >Uomini</label
          >
        </div>
        <div class="flex-container flex-row align-center ns pnt">
          <input
            type="checkbox"
            id="other"
            value="other"
            v-model="localPost.target.gender"
            :disabled="localPost.target.gender.includes('all')"
          />
          <label class="inflooad_post_specs_genderage_select" for="other"
            >Altro</label
          >
        </div>
      </div>
    </div>
    <div class="inflooad_post_specs_age">
      <label class="inflooad_post_specs_tariffa_label">Età:</label>
      <div
        class="flex-container flex-row flex-wrap overflow-auto innovativestudio_contenitore_eta inflooad_genderage_select_block"
      >
        <div class="flex-container flex-row align-center ns pnt">
          <input
            type="checkbox"
            id="all-ages"
            value="all"
            v-model="localPost.target.age"
            @change="onAllAgesChange"
          />
          <label class="inflooad_post_specs_genderage_select" for="all-ages"
            >Tutte le età</label
          >
        </div>
        <div class="flex-container flex-row align-center ns pnt">
          <input
            type="checkbox"
            id="18-24"
            value="18-24"
            v-model="localPost.target.age"
            :disabled="localPost.target.age.includes('all')"
          />
          <label class="inflooad_post_specs_genderage_select" for="18-24"
            >18-24</label
          >
        </div>
        <div class="flex-container flex-row align-center ns pnt">
          <input
            type="checkbox"
            id="25-34"
            value="25-34"
            v-model="localPost.target.age"
            :disabled="localPost.target.age.includes('all')"
          />
          <label class="inflooad_post_specs_genderage_select" for="25-34"
            >25-34</label
          >
        </div>
        <div class="flex-container flex-row align-center ns pnt">
          <input
            type="checkbox"
            id="35-44"
            value="35-44"
            v-model="localPost.target.age"
            :disabled="localPost.target.age.includes('all')"
          />
          <label class="inflooad_post_specs_genderage_select" for="35-44"
            >35-44</label
          >
        </div>
        <div class="flex-container flex-row align-center ns pnt">
          <input
            type="checkbox"
            id="45-54"
            value="45-54"
            v-model="localPost.target.age"
            :disabled="localPost.target.age.includes('all')"
          />
          <label class="inflooad_post_specs_genderage_select" for="45-54"
            >45-54</label
          >
        </div>
        <div class="flex-container flex-row align-center ns pnt">
          <input
            type="checkbox"
            id="55-64"
            value="55-64"
            v-model="localPost.target.age"
            :disabled="localPost.target.age.includes('all')"
          />
          <label class="inflooad_post_specs_genderage_select" for="55-64"
            >55-64</label
          >
        </div>
        <div class="flex-container flex-row align-center ns pnt">
          <input
            type="checkbox"
            id="65"
            value="65+"
            v-model="localPost.target.age"
            :disabled="localPost.target.age.includes('all')"
          />
          <label class="inflooad_post_specs_genderage_select" for="65"
            >65+</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  props: {
    post: Object,
  },
  setup(props) {
    const localPost = ref({ ...props.post });

    watch(
      () => props.post,
      (newPost) => {
        localPost.value = { ...newPost };
      },
      { deep: true }
    );

    watch(
      localPost,
      (newLocalPost) => {
        Object.assign(props.post, newLocalPost);
      },
      { deep: true }
    );

    const onAllGendersChange = () => {
      if (localPost.value.target.gender.includes("all")) {
        localPost.value.target.gender = ["all"];
      }
    };

    const onAllAgesChange = () => {
      if (localPost.value.target.age.includes("all")) {
        localPost.value.target.age = ["all"];
      }
    };

    return {
      localPost,
      onAllGendersChange,
      onAllAgesChange,
    };
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.overflow-auto {
  overflow: auto;
}

.ns {
  margin: 5px;
}

.pnt {
  cursor: pointer;
}

/* blocco delle specifiche del post */
.inflooad_post_specs {
  align-items: initial;
  cursor: pointer;
  border: 1px solid #2cbeff9c;
  padding: 10px;
  margin-bottom: 16px;
}

/* contenitore titolo ed immagine */
.inflooad_postdetails_firstrow {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
}

/* contenitore titolo*/
.inflooad_postdetails_contenttitle {
  grid-column: 1;
  grid-row: 1;
  align-self: start;
  justify-self: start;
  padding-right: 8px;
}

/* titolo */
.inflooad_postdetails_title {
  font-weight: bold !important;
  color: #15465c !important;
}
.inflooad_postdetails_selectedtext {
  margin-top: 10px !important;
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* immagine di riepilogo */
.inflooad_postdetails_selectedimage {
  max-width: 80px;
  width: 80px;
  height: auto;
  grid-column: 2;
  grid-row: 1;
  align-self: start;
  justify-self: end;
}

.inflooad_postdetails_selectedtext {
}

/* tipo di post 
.inflooad_post_specs_ghost {
  border-top: 1px solid #15465c;
  padding-top: 16px;
}*/
/* tariffe */
.inflooad_post_specs_tariffa {
  background-color: #f2f2f2 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  height: 25px !important;
  font-family: inherit !important;
  position: relative !important;
  border: none !important;
  margin-left: 10px !important;
}
.inflooad_post_specs_tariffa_label {
  font-family: "Bebas Neue", cursive;
  font-size: 18px !important;
}

/* tags */
.inflooad_post_specs_tags {
  margin-top: 0px;
  margin-bottom: 16px;
}
.inflooad_post_specs_tags_title {
  font-family: "Bebas Neue", cursive;
  font-size: 18px !important;
}
.inflooad_post_specs_tags_list > span {
  background: #2cbeff !important;
  font-weight: normal !important;
  padding: 1px 8px !important;
  color: #fff !important;
  font-size: 14px !important;
  border-radius: 3px !important;
  margin-right: 5px !important;
  align-items: center !important;
  cursor: text !important;
  white-space: nowrap !important;
}

/* id del post inpage */
.inflooad_post_specs_inpage_postid {
  background-color: #f2f2f2;
  font-size: 14px;
  line-height: 20px;
  height: 25px;
  font-family: inherit;
  position: relative;
  border: none !important;
  margin-left: 10px !important;
}

/* tipo di tariffa */
.inflooad_post_specs_pricetype {
  margin-top: 16px;
  margin-bottom: 16px;
}
.inflooad_post_specs_pricetype_sel {
  font-size: 12px !important;
}

/* budget totale */
.inflooad_post_specs_budgettotale {
  padding-bottom: 16px;
}

/* genderage */
.inflooad_post_specs_gender {
  border-top: 1px solid #15465c;
  padding-top: 16px;
  padding-bottom: 16px;
}
.inflooad_post_specs_age {
  border-top: 1px solid #15465c;
  padding-top: 16px;
}
.inflooad_post_specs_genderage_select {
  font-size: 13px !important;
}
.inflooad_genderage_select_block > div {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
</style>
