import { home } from "./routes/home";
import { tags, tagById } from "./routes/tags";
import { users, userById } from "./routes/users";
import { userscopes, userscopeByID } from "./routes/userscopes";
import { campaigns, campaignByID } from "./routes/campaigns";
import { socials, socialByID } from "./routes/socials";
import { login } from "./routes/login";
import { forgot } from "./routes/forgotpass";
import { reset } from "./routes/reset";
import { notfound } from "./routes/notfound";
import { mockup } from "./routes/mockup";
import {
  quotes,
  editQuote,
  quotePreview,
  completeBrief,
} from "./routes/quotes";
import { sudo } from "./routes/sudo";

export const routes = [
  home,
  users,
  userById,
  userscopes,
  userscopeByID,
  campaigns,
  campaignByID,
  socials,
  socialByID,
  tagById,
  tags,
  login,
  forgot,
  reset,
  notfound,
  mockup,
  quotes,
  editQuote,
  quotePreview,
  completeBrief,
  sudo,
];
