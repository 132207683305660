<template>
  <div>
    <div class="layout-list-header">
      <span class="layout-list-header-title bebas f20 pl16">Preventivi</span>
      <router-link to="/quotes/edit" class="btn-new">+</router-link>
    </div>
    <div class="layout-list-content infotronik_campagne_campaignlist">
      <table class="quotes_listaesterna_tabella">
        <thead class="quotes_listaesterna_tabella_titoli">
          <tr>
            <th>Inserzionista</th>
            <th>Campagna</th>
            <th>Data</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody class="quotes_listaesterna_tabella_body">
          <tr v-for="quote in sortedQuotes" :key="quote.Cod">
            <td>{{ quote.Campaign?.Advertiser.UserName ?? "n/a" }}</td>
            <td>{{ quote.Campaign?.Name ?? "n/a" }}</td>
            <td>{{ new Date(quote.Created_at).toLocaleString() }}</td>
            <td>
              <button @click="previewQuote(quote)">Anteprima</button>
              <button @click="copyLink(quote.Cod)">Copia Link</button>
              <button v-if="quote.Status == 0" @click="confirmRefresh(quote)">
                Aggiorna
              </button>
              <router-link
                v-if="quote.Status == 0"
                class="q-router-link quotes_listaesterna_tabella_button_modifica"
                :to="{ name: 'editQuote', params: { id: quote.Cod } }"
                >Modifica</router-link
              >
              <button
                v-if="quote.Status == 0 || quote.Status == 1"
                @click="confirmAdvance(quote)"
                class="q-router-link quotes_listaesterna_tabella_button_modifica"
              >
                Completa
              </button>
              <button
                v-if="quote.Status == 0"
                class="btn-delete"
                @click="confirmDelete(quote)"
              >
                Cancella
              </button>
              <button
                v-if="quote.Status == 1"
                class="btn-delete"
                @click="resetStatus(quote)"
              >
                Reset
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ConfirmPanel
      v-if="confirmAdvanceVisible"
      :title="'Avanzamento ' + quoteSelected.Cod"
      :confirmButtonText="'Procedi'"
      :message="
        'Codice preventivo: ' +
        quoteSelected.Cod +
        '\n' +
        'Del: ' +
        new Date(quoteSelected.Created_at).toLocaleString() +
        '\n' +
        'Campagna: ' +
        quoteSelected.Campaign.Name +
        '\n' +
        'Inserzionista: ' +
        quoteSelected.Campaign.Advertiser.UserName +
        '\n' +
        '\n' +
        '<b>Procedere alla compilazione di ContentWave e InflooAd ?'
      "
      @allow-confirm="onAdvanceConfirm"
      @deny-confirm="confirmAdvanceVisible = false"
    >
    </ConfirmPanel>
    <ConfirmPanel
      v-if="confirmRefreshVisible"
      :title="'Aggiorna ' + quoteSelected.Cod"
      :confirmButtonText="'Aggiorna'"
      :message="
        'Codice preventivo: ' +
        quoteSelected.Cod +
        '\n' +
        'Del: ' +
        new Date(quoteSelected.Created_at).toLocaleString() +
        '\n' +
        'Campagna: ' +
        quoteSelected.Campaign.Name +
        '\n' +
        'Inserzionista: ' +
        quoteSelected.Campaign.Advertiser.UserName +
        '\n' +
        '\n' +
        '<b>Aggiornare e rigenerare</b> descrizione target e metadati editor per questo preventivo ?'
      "
      @allow-confirm="onRefreshConfirm"
      @deny-confirm="confirmRefreshVisible = false"
    >
    </ConfirmPanel>
    <ConfirmPanel
      v-if="confirmDeleteVisible"
      :title="'Elimina ' + quoteSelected.Cod"
      :message="
        'Codice preventivo: ' +
        quoteSelected.Cod +
        '\n' +
        'Del: ' +
        new Date(quoteSelected.Created_at).toLocaleString() +
        '\n' +
        'Campagna: ' +
        quoteSelected.Campaign.Name +
        '\n' +
        'Inserzionista: ' +
        quoteSelected.Campaign.Advertiser.UserName +
        '\n' +
        '\n' +
        'Sei sicuro di voler <b>eliminare</b> il preventivo ?'
      "
      ;
      @allow-confirm="onDeleteConfirm"
      @deny-confirm="confirmDeleteVisible = false"
    >
    </ConfirmPanel>
  </div>
</template>

<script>
import axios from "axios";
import { ApiEndPoints } from "@/api/Endpoints";
import ConfirmPanel from "@/components/ConfirmPanel";

export default {
  name: "QuotesList",
  components: {
    ConfirmPanel,
  },
  data() {
    return {
      quotes: [],
      confirmDeleteVisible: false,
      confirmRefreshVisible: false,
      confirmAdvanceVisible: false,
      quoteSelected: null,
      isAsyncProcessing: false, // variabile di stato per bloccare i pulsanti dei ConfirmPanel mentre processano
    };
  },
  computed: {
    sortedQuotes() {
      return [...this.quotes].sort(
        (a, b) => new Date(b.Created_at) - new Date(a.Created_at)
      );
    },
  },
  mounted() {
    this.getQuotes();
  },
  methods: {
    getQuotes() {
      axios
        .get(ApiEndPoints.QUOTES + "/all")
        .then((response) => {
          this.quotes = response.data.map((quoteJson) => JSON.parse(quoteJson));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    previewQuote(quote) {
      //this.$router.push({ name: "quotePreview", params: { cod: quote.Cod } });
      const url = this.$router.resolve({
        name: "quotePreview",
        params: { cod: quote.Cod },
      }).href;
      window.open(url, "_blank");
    },
    confirmRefresh(quote) {
      this.quoteSelected = quote;
      this.confirmRefreshVisible = true;
    },
    confirmDelete(quote) {
      this.quoteSelected = quote;
      this.confirmDeleteVisible = true;
    },
    confirmAdvance(quote) {
      if (quote.Status === 1) {
        this.$router.push({
          name: "completeBrief",
          params: { cod: quote.Cod },
        });
        return;
      }
      this.quoteSelected = quote;
      this.confirmAdvanceVisible = true;
    },
    async resetStatus(quote) {
      await axios.post(`${ApiEndPoints.QUOTES}/resetStatus`, {
        Cod: quote.Cod,
      });
      quote.Status = 0;
    },
    async onDeleteConfirm() {
      this.isAsyncProcessing = true;
      try {
        /*
        await axios.delete(
          `${ApiEndPoints.QUOTES}/delete/${this.quoteSelected.Cod}`
        );
        */
        await axios.post(`${ApiEndPoints.QUOTES}/delete`, {
          Cod: this.quoteSelected.Cod,
        });
        this.quotes = this.quotes.filter(
          (quote) => quote.Cod !== this.quoteSelected.Cod
        );
        this.confirmDeleteVisible = false;
        this.quoteSelected = null;
      } catch (error) {
        console.error("Errore nella cancellazione del preventivo:", error);
      } finally {
        this.isAsyncProcessing = false;
      }
    },
    async onRefreshConfirm() {
      this.isAsyncProcessing = true;
      try {
        await axios.post(`${ApiEndPoints.QUOTES}/refresh`, {
          Cod: this.quoteSelected.Cod,
        });
        this.confirmRefreshVisible = false;
        this.quoteSelected = null;
      } catch (error) {
        console.error("Errore nell'aggiornamento del preventivo:", error);
      } finally {
        this.isAsyncProcessing = false;
      }
    },
    async onAdvanceConfirm() {
      this.isAsyncProcessing = true;
      try {
        const response = await axios.post(`${ApiEndPoints.QUOTES}/advance`, {
          Cod: this.quoteSelected.Cod,
        });
        if (response.status === 200) {
          this.$router.push({
            name: "completeBrief",
            params: { cod: this.quoteSelected.Cod },
          });
        }
      } catch (error) {
        console.error("Errore nell'avanzamento del preventivo:", error);
      } finally {
        this.isAsyncProcessing = false;
      }
    },
    copyLink(cod) {
      const link = `${window.location.origin}/quotes/preview/${cod}`;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          console.log("Link copiato negli appunti:", link);
        })
        .catch((err) => {
          console.error("Errore nella copia del link:", err);
        });
    },
  },
};
</script>

<style scoped>
.layout-list-header-title {
  font-size: 20px;
  font-weight: bold;
}

.layout-list-content {
  padding: 16px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f5f5f5;
}

button {
  margin-right: 8px;
  padding: 4px 8px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.q-router-link {
  margin-right: 8px;
  padding: 4px 8px;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.q-router-link:hover {
  background-color: #218838;
}

.btn-delete {
  background-color: #dc3545;
}

.btn-delete:hover {
  background-color: #c82333;
}
</style>
