<template>
  <div class="strategy-row">
    <div class="strategy-details">
      <div class="strategy_details_content_title">
        <label class="bebas strategy_details_title" for="strategy-details">{{ strategy.name }}</label>
      </div>
      <div class="strategy_details_content_tipostrategia">
        Tipo: <strong>{{ strategy.type }}</strong>
      </div>
      <div class="inflooad_strategy_rowlist">
        <div class="inflooad_strategy_rowlist_block" v-for="(goal, index) in strategy.goals" :key="index">
          <input
            type="radio"
            :value="goal"
            :checked="isSelected(goal)"
            @change="selectGoal(goal)"
          />
          <div class="strategy_details_content_caratteristica">
            Budget: <strong>€ {{ goal.budget }}</strong>
          </div>
          <div class="strategy_details_content_caratteristica">
            Post: <strong>{{ goal.posts }}</strong>
          </div>
          <div class="strategy_details_content_caratteristica">
            Post Premium: <strong>{{ goal.posts_premium }}</strong>
          </div>
          <div class="strategy_details_content_caratteristica">
            Reach: <strong>{{ goal.reach }}</strong>
          </div>
          <div class="strategy_details_content_caratteristica">
            Impressions: <strong>{{ goal.impressions }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    strategy: Object,
    selectedGoal: Object,
  },
  methods: {
    isSelected(goal) {
      return this.selectedGoal === goal;
    },
    selectGoal(goal) {
      this.$emit("update:selectedGoal", goal);
    },
  },
};
</script>

<style scoped>
/* blocco strategia */
.strategy-row {
  display: flex;
  align-items: initial;
  cursor: pointer;
  border: 1px solid #2cbeff9c;
  padding: 10px;
  margin-bottom: 16px;
}
.strategy-details {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

/* titolo strategia */
.strategy_details_content_title {
  margin-bottom: 10px;
}
.strategy_details_title {
  font-weight: bold !important;
  color: #15465c !important;
}

/* tipo strategia */
.strategy_details_content_tipostrategia {
  font-family: 'Bebas Neue', cursive;
  font-size: 18px !important;
  margin-bottom: 10px;
}

/* lista di righe sotto strategie */
.inflooad_strategy_rowlist {
  display: grid;
  grid-template-columns: repeat(1, 1fr) !important;
  gap: 10px;
}
/* riga di strategia */
.inflooad_strategy_rowlist_block {
  border-top: 1px solid #15465c;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
/* punti della riga della strategia */
.strategy_details_content_caratteristica {
  font-family: 'Bebas Neue', cursive;
  font-size: 18px !important;
}
</style>
