<template>
  <!-- Il tuo codice template rimane invariato -->
  <div id="CodyWidgetLoader"></div>
</template>

<script>
// Importa le tue dipendenze come prima

export default {
  // I tuoi componenti rimangono invariati
  mounted() {
    this.loadScript();
  },
  methods: {
    isCorrectDomain() {
      // Implemento la logica per verificare il dominio
      const allowedDomain = "inflooendo.com"; // Sostituire con il dominio corretto
      return window.location.hostname.includes(allowedDomain);
    },
    loadScript() {
      if (!this.isCorrectDomain()) {
        return;
      }
      window.codySettings = {
        widget_id: "9bd3f04c-5d29-4ddc-ab0f-af95e6ac0807",
      };
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = "https://trinketsofcody.com/cody-widget.js";
      document.head.appendChild(script);
    },
  },
  // Resto del tuo codice...
};
</script>
