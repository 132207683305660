<template>
  <div>
    <div>
      <button @click="logQuote">Log Quote</button>
      <button @click="logConfirmedPosts">Log Confirmed Posts</button>
      <button @click="logSelectedEditors">Log Selected Editors</button>
      <textarea v-model="textareaContent" rows="10" cols="50"></textarea>
    </div>
    <br />
    <hr class="infotronik_contentwave_separatore" />
    <br />
  </div>
  <div class="quote_preview_html_container">
    <!-- slide intro -->
    <div class="quote_preview_fissa section_html prima_slide_fissa">
      <img
        :src="inflooendo_logo"
        alt="Logo Cliente"
        class="inflooad_preview_logocentrato"
      />
    </div>
    <!-- tabella -->
    <div class="quote_preview_fissa section_html slide_riepilogo_posts">
      <div class="slide_riepilogo_posts_title">Pianificazione:</div>
      <div class="slide_riepilogo_posts_content">
        <table class="slide_riepilogo_posts_table">
          <thead>
            <tr>
              <th>Post</th>
              <th>Data</th>
              <th>Editore</th>
              <th>Social Suggerito</th>
              <th>Target</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(editorbox, index) in filteredEditors" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ formattedStartDate }}</td>
              <td>{{ editorbox.editor.name }}</td>
              <td v-if="editorbox.editor.discriminator === 'Facebook'">FB</td>
              <td v-else>IG</td>
              <td>
                <span
                  v-for="(tag, index2) in editorbox.editor.socialTags"
                  :key="index2"
                  >{{ tag.tag.name }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <p class="slide_riepilogo_posts_desc">
          Seguono le proposte di copy realizzare in base allo stile editoriale
          di ogni testata.<br />Hanno quindi lunghezze e tone of voice
          differenti.
        </p>
      </div>
    </div>
    <!-- editori allineati -->
    <div
      class="quote_preview_slide quote_preview_slide_bicolor_all section_html"
      v-for="(editorbox, index) in filteredEditors"
      :key="index"
    >
      <!-- prima colonna azzurra -->
      <div
        class="inflooad_preview_colonna_slice_editore inflooad_preview_colonna_slice_datieditore"
      >
        <!-- titolo -->
        <p class="inflooad_preview_colonna_slice_datieditore_titolo">
          {{ editorbox.editor.name }}
        </p>
        <!-- social -->
        <p
          v-if="editorbox.editor.discriminator === 'Facebook' && editorbox.props.ghost"
          class="inflooad_preview_colonna_slice_datieditore_social"
        >
          <span class="inflooad_preview_colonna_slice_datieditore_social_bold">Post:</span>
          Facebook in Dark
        </p>
        <p
          v-else-if="editorbox.editor.discriminator === 'Facebook' && !editorbox.props.ghost"
          class="inflooad_preview_colonna_slice_datieditore_social"
        >
          <span class="inflooad_preview_colonna_slice_datieditore_social_bold">Post:</span>
          Facebook in Page
        </p>
        <p
          v-else-if="editorbox.editor.discriminator === 'Instagram' && editorbox.props.ghost"
          class="inflooad_preview_colonna_slice_datieditore_social"
        >
          <span class="inflooad_preview_colonna_slice_datieditore_social_bold">Post:</span>
          Instagram in Dark
        </p>
        <p
          v-else-if="editorbox.editor.discriminator === 'Instagram' && !editorbox.props.ghost"
          class="inflooad_preview_colonna_slice_datieditore_social"
        >
          <span class="inflooad_preview_colonna_slice_datieditore_social_bold">Post:</span>
          Instagram in Page
        </p>
        <p v-else class="inflooad_preview_colonna_slice_datieditore_social">
          <span class="inflooad_preview_colonna_slice_datieditore_social_bold">Post:</span>
          Other
        </p>
        <!-- data di pubblicazione -->
        <p class="inflooad_preview_colonna_slice_datieditore_social">
          <span class="inflooad_preview_colonna_slice_datieditore_social_bold"
            >Data di Pubblicazione:</span
          >
          {{ formattedStartDate }}
        </p>
        <!-- pagina da taggare -->
        <p v-if="editorbox.props.pagelink && editorbox.props.pagelink.trim() !== ''" class="inflooad_preview_colonna_slice_datieditore_social">
          <span class="inflooad_preview_colonna_slice_datieditore_social_bold"
            >Pagina da Taggare:</span
          >
          {{ editorbox.props.pagelink }}
        </p>
        <!-- elenco hashtag -->
        <p v-if="editorbox.props.tags && editorbox.props.tags.trim() !== ''" class="inflooad_preview_colonna_slice_datieditore_social">
          <span class="inflooad_preview_colonna_slice_datieditore_social_bold"
            >#:</span
          >
          {{ editorbox.props.tags }}
        </p>
      </div>
      <!-- seconda colonna copy e immagini -->
      <div
        class="inflooad_preview_colonna_slice_editore inflooad_preview_colonna_copyimm"
      >
        <div class="inflooad_preview_contenitore_colonna_copyimm">
        <!-- righe -->
          <div
            v-for="(postsbox, index2) in editorbox.posts"
            :key="index2"
            class="inflooad_preview_contenitore_copyimm"        
          >
            <!-- copy -->
            <div
            :class="{
              inflooad_preview_colonna_slice_editore_copyex:
                editorbox.posts.length === 1,
              inflooad_preview_colonna_slice_editore_copyex2:
                editorbox.posts.length === 2,
              inflooad_preview_colonna_slice_editore_copyex3:
                editorbox.posts.length === 3,
              inflooad_preview_colonna_slice_editore_copyex4:
                editorbox.posts.length === 4,
            }">
              <span class="inflooad_preview_colonna_slice_editore_copy_title"
              >Copy {{ String.fromCharCode(65 + index2) }}:</span
              ><br />
              {{ postsbox.text }}
            </div>
            <!-- fine copy -->
            <!-- immagine -->
            <div
            :class="{
              inflooad_preview_colonna_slice_editore_imm:
                editorbox.posts.length === 1,
              inflooad_preview_colonna_slice_editore_imm2:
                editorbox.posts.length === 2,
              inflooad_preview_colonna_slice_editore_imm3:
                editorbox.posts.length === 3,
              inflooad_preview_colonna_slice_editore_imm4:
                editorbox.posts.length === 4,
            }">
              <img v-if="postsbox.image" :src="postsbox.image" alt="Proposta Grafica" />
              <img v-else src="../../css/img/placeholder.png" alt="Placeholder Proposta" /> 
            </div>
            <!-- fine immagine -->
          </div>
          <!-- righe -->
        </div>
      </div>
      <!-- fine seconda colonna copy ed immagini -->
    </div>
  </div>
</template>

<script>
import inflooendo_logo from "@/assets/quotes/logocolorato.png";
import { ref, computed, watch } from "vue";

export default {
  props: {
    quote: {
      type: Object,
      required: true,
    },
    confirmedPosts: {
      type: Array,
      required: true,
    },
    selectedEditors: {
      type: Array,
      required: true,
    },
    campaignDates: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedStartDate() {
      const startDate = this.campaignDates.startDate
        ? new Date(this.campaignDates.startDate)
        : new Date();
      const day = String(startDate.getDate()).padStart(2, "0");
      const month = String(startDate.getMonth() + 1).padStart(2, "0");
      const year = startDate.getFullYear();
      return `${day}/${month}/${year}`;
    },
  },
  setup(props) {
    const textareaContent = ref("");

    watch(
      () => props.selectedEditors,
      (newValue) => {
        console.log("selectedEditors changed:", newValue);
      },
      { deep: true }
    );

    const filteredEditors = computed(() => {
      return props.selectedEditors.filter(
        (item) => item.posts && item.posts.length > 0
      );
    });

    return { filteredEditors, textareaContent, inflooendo_logo };
  },

  methods: {
    logQuote() {
      console.log("Quote:", this.quote);
      this.textareaContent = JSON.stringify(this.quote, null, 2);
    },
    logConfirmedPosts() {
      console.log("Confirmed Posts:", this.confirmedPosts);
      this.textareaContent = JSON.stringify(this.confirmedPosts, null, 2);
    },
    logSelectedEditors() {
      console.log("SelectedEditors", this.selectedEditors);
      this.textareaContent = JSON.stringify(this.selectedEditors, null, 2);
    },
  },
};
</script>

<style scoped>
button {
  margin: 5px;
  padding: 10px;
  background-color: #2cbeff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #1a8cd8;
}

textarea {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: monospace;
}

/* separatore */
.infotronik_contentwave_separatore {
  width: 80% !important;
  background: #2cbeff !important;
  height: 1px !important;
  margin-top: 30px;
  margin-bottom: 30px;
}

/* contenitore slide preview */
.quote_preview_html_container {
  width: 1356px !important;
  max-width: 1356px !important;
  min-width: 1356px !important;
  overflow-y: none !important;
  scrollbar-width: thin;
  scrollbar-color: #2cbeff #ffffff;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

/* slides html */
.quote_preview_fissa {
  padding: 0px !important;
  margin: 0px !important;
}
.section_html {
  margin-bottom: 20px !important;
  min-height: 762.75px !important;
}

/* prima slide */
.prima_slide_fissa {
  position: relative;
  background-image: url("~@/assets/quotes/Background.jpg") !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.inflooad_preview_logocentrato {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 350px;
  height: auto;
}

/* tabella di riepilogo */
.slide_riepilogo_posts {
  background-color: white !important;
  padding-top: 40px !important;
  padding-right: 40px !important;
  padding-left: 40px !important;
}
.slide_riepilogo_posts_title {
  font-size: 35px;
  font-weight: 700;
  color: #007bff !important;
}
.slide_riepilogo_posts_content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 720px !important;
}
.slide_riepilogo_posts_table {
  width: 100%;
  margin-bottom: 40px;
  border-collapse: collapse;
}
.slide_riepilogo_posts_table th {
  background-color: #007bff;
  color: white;
  border: 2px solid #bbbbbb;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.slide_riepilogo_posts_table td {
  border: 2px solid #bbbbbb;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
/* testo sotto tabella */
.slide_riepilogo_posts_desc {
}

/* slides editori */
.quote_preview_slide_bicolor {
  background-color: white !important;
  display: grid;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  align-items: stretch;
}
.inflooad_preview_colonna_slice_editore {
  max-height: 100% !important;
  overflow: hidden;
  display: flex;
}
/* dati editore */
.inflooad_preview_colonna_slice_datieditore {
  flex-direction: column;
  justify-content: flex-start;
  background-color: #007bff !important;
  padding-left: 30px;
  padding-top: 80px;
}
.inflooad_preview_colonna_slice_datieditore_titolo {
  font-size: 26px;
  font-weight: 700;
  color: white;
}
.inflooad_preview_colonna_slice_datieditore_social {
  color: white;
  font-weight: 200 !important;
}
.inflooad_preview_colonna_slice_datieditore_social_bold {
  color: white;
  font-weight: 600;
}

/* copy editori */
.inflooad_preview_colonna_slice_editore_copy {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.inflooad_preview_colonna_slice_editore_copyex {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
}
.inflooad_preview_colonna_slice_editore_copyex2 {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.inflooad_preview_colonna_slice_editore_copyex3 {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.inflooad_preview_colonna_slice_editore_copyex4 {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.inflooad_preview_colonna_slice_editore_copy_title {
  font-size: 15px;
  font-weight: 900;
}
/* immagini editori */
.inflooad_preview_colonna_slice_editore_content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.inflooad_preview_colonna_slice_editore_immaginec {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* dimensione immagini */
.inflooad_preview_colonna_slice_editore_imm {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: flex;
  justify-content: center;
}
.inflooad_preview_colonna_slice_editore_imm > img {
  width: 90%;
  height: auto;
}
.inflooad_preview_colonna_slice_editore_imm2 {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: flex;
  justify-content: center;
}
.inflooad_preview_colonna_slice_editore_imm2 > img {
  width: 300px;
  height: auto;
}
.inflooad_preview_colonna_slice_editore_imm3 {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: flex;
  justify-content: center;
}
.inflooad_preview_colonna_slice_editore_imm3 > img {
  width: 240px;
  height: auto;
}
.inflooad_preview_colonna_slice_editore_imm4 {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: flex;
  justify-content: center;
}
.inflooad_preview_colonna_slice_editore_imm4 > img {
  width: 200px;
  height: auto;
}

/* editori con copy ed immagini allineati*/
.quote_preview_slide_bicolor_all { 
  background-color: white !important; 
  display: grid; 
  grid-template-columns: 1fr 2fr !important; 
  gap: 10px; 
  margin-top: 0px; 
  margin-bottom: 0px; 
  align-items: stretch;
}
.inflooad_preview_contenitore_colonna_copyimm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.inflooad_preview_colonna_copyimm {
  background-color: white !important; 
  display: grid;
  gap: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.inflooad_preview_contenitore_copyimm {
  background-color: white !important; 
  display: grid; 
  grid-template-columns: 1fr 1fr !important; 
  gap: 10px; 
  margin-top: 2px; 
  margin-bottom: 2px; 
  align-items: stretch;
}
</style>
