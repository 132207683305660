<template>
  <teleport to="#overlay">
    <div class="overlay w100 h100 abs">
      <div class="overlay-panel rel pb24 contentwave_pannello_generazione_ai">
        <form
          class="flex-container flex-col min-height-inherit contentwave_pannello_generazioneai_form"
          id="panel"
          @submit.prevent="onSubmit"
        >
          <div class="panel-header p24 contentwave_pannello_generazioneai_titolo">
            <div class="mini-logo"></div>
            <h2 class="panel-title nomargin bebas">{{ modalTitle }}</h2>
          </div>

          <keep-alive>
            <div
              v-if="step === 0"
              class="flex-container flex-col pl24 pr24 pb24 contentwave_pannello_generazioneai_bloccotesto"
            >
              <div v-if="showOptions" class="radio-group contentwave_pannello_generazioneai_bloccogenera">
                <label>
                  <input type="radio" value="post" v-model="selectedOption" />
                  Contenuto
                </label>
                <label>
                  <input type="radio" value="image" v-model="selectedOption" />
                  Immagine
                </label>
                <label>
                  <input type="radio" value="video" v-model="selectedOption" disabled/>
                  Video
                </label>
                <label>
                  <input type="radio" value="both" v-model="selectedOption" />
                  Contenuto ed Immagine
                </label>               
              </div>
              <textarea
                class="flex-container flex-col add-description pb16 infotronik_quotes_popup_textarea contentwave_pannello_generazioneai_testo"
                v-model="description"
                placeholder="Inserisci una breve descrizione"
              ></textarea>
            </div>
          </keep-alive>

          <div class="flex-container panel-footer abs w100 contentwave_pannello_generazioneai_pulsanti">
            <div class="flex-container">
              <input
                type="reset"
                value="Annulla"
                class="w100 noborder nopadding bebas"
                @click.prevent="closeModal"
              />
              <input
                type="submit"
                value="Conferma"
                class="w100 noborder nopadding bebas"
                :disabled="description.trim().length === 0"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </teleport>
</template>

<script>
import { ref, computed, onMounted } from "vue";

export default {
  name: "DescriptionModal",
  props: {
    title: {
      type: String,
      default: "Digitare la descrizione",
    },
    showOptions: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "submit"],
  setup(props, { emit }) {
    const step = ref(0);
    const description = ref("");
    const selectedOption = ref(null); // Opzione predefinita

    const closeModal = () => {
      emit("close");
    };

    const onSubmit = () => {
      const result = {
        option: selectedOption.value,
        description: description.value.trim(),
      };
      emit("submit", result);
      closeModal();
    };

    const modalTitle = computed(() => props.title);

    onMounted(() => {
      if (props.showOptions) {
        selectedOption.value = "post";
      }
    });

    return {
      step,
      description,
      closeModal,
      onSubmit,
      modalTitle,
      selectedOption,
    };
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.overlay-panel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.panel-title {
  font-size: 24px;
}

.panel-footer {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.radio-group {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.radio-group label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio-group input[type="radio"] {
  margin-right: 8px;
  cursor: pointer;
}

/* modale generazione ai */
.contentwave_pannello_generazione_ai {

}
.contentwave_pannello_generazioneai_titolo {
  padding: 0px !important;
  margin-bottom: 15px !important;
}
.contentwave_pannello_generazioneai_bloccotesto {
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex; 
  justify-content: center; 
  align-items: center;
}
/* descrizione per la generazione testo ed immagini ai */
.contentwave_pannello_generazioneai_testo {
  padding: 0px !important;
  overflow-y: auto !important;
  width: 100% !important;
  min-height: 10em !important;
}
.contentwave_pannello_generazioneai_testo::-webkit-scrollbar { 
  width: 10px;
} 
.contentwave_pannello_generazioneai_testo::-webkit-scrollbar-track { 
  background: #f1f1f1; 
} 
.contentwave_pannello_generazioneai_testo::-webkit-scrollbar-thumb { 
  background-color: #2cbeff; 
  border-radius: 10px; 
  border: 3px solid #f1f1f1;  
}
/* pulsanti generazione */
.contentwave_pannello_generazioneai_pulsanti {
  padding: 0px !important;
}

/* scelta contenuto da generare */
.contentwave_pannello_generazioneai_bloccogenera > label {
  font-size: 12px !important;
}
.contentwave_pannello_generazioneai_bloccogenera > label > input {
  margin-right: 5px !important;
  margin-top: 1px !important;
}
</style>
