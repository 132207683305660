<template>
  <div class="strategy">
    <h2 class="infotronik_title_stategia">
      #{{ strategyOrder }} {{ strategyName }}
    </h2>

    <div class="additional-fields infotronik_stategia_campi">
      <label for="name">Nome:</label>
      <input v-model="localStrategy.name" id="name" type="text" />
    </div>

    <div
      class="additional-fields infotronik_stategia_campi infotronik_strategia_sceltamedia"
    >
      <label>Tipo di Strategia:</label>
      <div class="infotronik_stategia_campi_radio">
        <label class="la-radio" for="type-image">Image</label>
        <input
          type="radio"
          id="type-image"
          value="image"
          v-model="localStrategy.type"
        />
      </div>
      <div class="infotronik_stategia_campi_radio">
        <label class="la-radio" for="type-video-reach">Video (Reach)</label>
        <input
          type="radio"
          id="type-video-reach"
          value="video_reach"
          v-model="localStrategy.type"
        />
      </div>
      <div class="infotronik_stategia_campi_radio">
        <label class="la-radio" for="type-video-view">Video (View)</label>
        <input
          type="radio"
          id="type-video-view"
          value="video_view"
          v-model="localStrategy.type"
        />
      </div>
    </div>

    <div
      class="additional-fields infotronik_stategia_campi infotronik_strategia_sceltavisualizzazioni"
      v-if="localStrategy.type === 'video_view'"
    >
      <label>Visualizzazioni Preferite:</label>
      <div class="infotronik_stategia_campi_radio">
        <label class="la-radio" for="preferred-views-3s">3 sec</label>
        <input
          type="radio"
          id="preferred-views-3s"
          value="views_3s"
          v-model="localStrategy.preferred_views"
        />
      </div>
      <div class="infotronik_stategia_campi_radio">
        <label class="la-radio" for="preferred-views-15s">15 sec</label>
        <input
          type="radio"
          id="preferred-views-15s"
          value="views_15s"
          v-model="localStrategy.preferred_views"
        />
      </div>
      <div class="infotronik_stategia_campi_radio">
        <label class="la-radio" for="preferred-views-both">Entrambe</label>
        <input
          type="radio"
          id="preferred-views-both"
          value="both"
          v-model="localStrategy.preferred_views"
        />
      </div>
    </div>

    <div
      v-for="(goal, index) in localStrategy.goals"
      :key="index"
      class="goal-item infotronik_stategia_goal"
    >
      <Goal
        :goal="goal"
        :strategyType="localStrategy.type"
        :preferredViews="localStrategy.preferred_views"
        @update:goal="updateGoal(index, $event)"
      />
      <button class="infotronik_stategia_removegoal" @click="removeGoal(index)">
        Rimuovi Riga
      </button>
    </div>

    <hr class="infotronik_quotes_separatore_interno" />

    <button class="infotronik_stategia_addgoal" @click="addGoal">
      Aggiungi Riga
    </button>

    <div
      class="additional-fields infotronik_stategia_campi infotronik_stategia_budget_testocalce"
    >
      <label for="footerText">Testo in calce:</label>
      <textarea
        v-model="localStrategy.footerText"
        id="footerText"
        rows="4"
      ></textarea>
    </div>
  </div>
</template>

<script>
import Goal from "./Goal.vue";

export default {
  name: "Strategy",
  components: {
    Goal,
  },
  props: {
    strategy: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      localStrategy: {
        type: "image", // Default type
        goals: [this.newGoal()],
        preferred_views: "both",
        order: 0,
        name: "",
        footerText: "",
      },
    };
  },
  created() {
    if (this.strategy) {
      this.localStrategy = { ...this.strategy };
    }
    if (this.localStrategy.goals.length === 0) {
      this.localStrategy.goals.push(this.newGoal());
    }
  },
  computed: {
    strategyOrder() {
      return this.localStrategy.order;
    },
    strategyName() {
      return this.localStrategy.name;
    },
  },
  watch: {
    localStrategy: {
      handler(newLocalStrategy) {
        this.$emit("update:strategy", newLocalStrategy);
      },
      deep: true,
    },
  },
  methods: {
    newGoal() {
      return {
        budget: 0,
        posts: 0,
        posts_premium: 0,
        reach: 0,
        impressions: 0,
        impressions_min: 0,
        impressions_max: 0,
        views_3s: 0,
        views_15s: 0,
      };
    },
    addGoal() {
      this.localStrategy.goals.push(this.newGoal());
    },
    removeGoal(index) {
      this.localStrategy.goals.splice(index, 1);
    },
    updateGoal(index, updatedGoal) {
      this.localStrategy.goals[index] = updatedGoal;
    },
  },
};
</script>

<style scoped>
/* blocco strategia 
.strategy {
  margin: 2rem;
}*/

.goal-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.goal-item button {
  margin-left: 1rem;
}
.additional-fields {
  margin-top: 2rem;
}
.additional-fields label {
  display: block;
  margin-bottom: 0.5rem;
}
.additional-fields input,
.additional-fields textarea {
  margin-bottom: 1rem;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
}
.la-radio {
  cursor: pointer;
}
input[type="radio"] {
  cursor: pointer;
}
</style>
