<!-- Spinner.vue -->
<template>
  <div class="spinner-container">
    <div v-if="text" class="spinner-text">{{ text }}</div>
    <div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    text: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
.spinner-container {
  display: flex;
  flex-direction: column; /* Assicura che gli elementi siano disposti in colonna */
  justify-content: center;
  align-items: center;
  height: 100%; /* Assicurati che il contenitore abbia un'altezza, altrimenti non sarà visibile */
}

.spinner-text {
  margin-bottom: 1rem;
  text-align: center;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #2bbeff;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2bbeff transparent transparent transparent;
}

.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
  50% {
    border-color: #39f6ff transparent transparent transparent;
  }
}
</style>
