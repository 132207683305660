<template>
  <div v-if="localElement">
    <h3 class="contentwave_editor_title">{{ EditorTitle }}</h3>
    <button class="contentwave_editor_rimuovieditore" @click="removeElement">
      Rimuovi Editore
    </button>

    <div class="contentwave_post_specs_ghost">
      <label>Ghost:</label>
      <input
        type="checkbox"
        v-model="localElement.props.ghost"
        @change="emitElementUpdate"
      />
    </div>
    <div
      class="contentwave_post_specs_ghost_postid"
      v-if="!localElement.props.ghost"
    >
      <label>InPage PostID:</label>
      <input
        type="text"
        v-model="localElement.props.onlinePostId"
        @input="emitElementUpdate"
      />
    </div>
    <div class="contentwave_post_specs_hashtags">
      <label>Hashtags:</label>
      <input
        type="text"
        v-model="localElement.props.tags"
        @input="emitElementUpdate"
      />
    </div>
    <div class="contentwave_post_specs_pakelink">
      <label>Page Link:</label>
      <input
        type="text"
        v-model="localElement.props.pagelink"
        @input="emitElementUpdate"
      />
    </div>

    <div class="contentwave_post_specs_addnposts">
      <label class="contentwave_editor_title_addpost" for="numPosts"
        >Aggiungi N. Post:</label
      >
      <input
        class="contentwave_editor_title_addnpost"
        type="number"
        v-model="numPosts"
        min="1"
        max="4"
        step="1"
      />
      <br />
      <button
        class="contentwave_editor_button_createpost"
        @click="openModal('postdescription')"
        :disabled="isGenerating"
      >
        {{ GenerateButtonText }}
      </button>
      <button
        class="contentwave_editor_button_createpost contentwave_editor_button_createpost_vuoto"
        @click="generateContent(null, null)"
      >
        Crea Post Vuoto/i
      </button>
    </div>
    <transition-group name="fade" tag="div">
      <div
        v-for="(post, index) in localElement.posts"
        :key="index"
        class="post-block"
        :class="{ selected: post.selected, confirmed: post.confirmed }"
      >
        <div>
          <textarea
            class="contentwave_editor_post_testopost"
            v-if="post.text !== null"
            v-model="post.text"
            placeholder="Testo del post"
            :disabled="post.confirmed"
          ></textarea>
          <Spinner v-else :text="'Generazione contenuto...'" />
        </div>
        <FileUpload
          v-if="post.image !== false"
          :editing="true"
          :fileurl="post.image"
          :className="'uploader-container flex-container flex-col flex-center-align'"
          :IaImageDescription="post.text"
          :type="'image'"
          @image-url-load="(imageUrl) => (post.image = imageUrl)"
          @image-deleted="() => (post.image = null)"
        />
        <Spinner v-else :text="'Generazione immagine...'" />
        <textarea
          class="contentwave_editor_post_commentopost"
          v-model="post.comment"
          placeholder="Commenti"
        ></textarea>
        <button
          class="contentwave_editor_button_removepost"
          @click="removePost(index)"
        >
          Rimuovi
        </button>
        <button
          class="contentwave_editor_button_selectpost"
          @click="toggleSelectPost(index)"
          :disabled="!post.text"
        >
          {{ post.selected ? "Deseleziona" : "Seleziona" }}
        </button>
        <button
          class="contentwave_editor_button_confirm"
          @click="confirmPost(index)"
          :disabled="!post.text"
        >
          {{ post.confirmed ? "Annulla conferma" : "Conferma" }}
        </button>
      </div>
    </transition-group>
    <DescriptionModal
      v-if="showDescModal"
      :title="DescModalTitle"
      :showOptions="true"
      @close="closeModals"
      @submit="handleModalSubmitted"
    />
  </div>
</template>

<script>
import { ref, reactive, computed, watch } from "vue";
import Spinner from "@/components/Spinner.vue";
import DescriptionModal from "@/components/quotes/DescriptionModal.vue";
import FileUpload from "@/components/FileUpload.vue";
import axios from "axios";
import { ApiEndPoints } from "@/api/Endpoints";
import { generateRandomId } from "@/utils/Common";

export default {
  props: {
    element: Object,
  },
  components: {
    Spinner,
    FileUpload,
    DescriptionModal,
  },
  emits: [
    "element-removed",
    "validation-changed",
    "post-confirmed",
    "post-removed",
    "update:selectedPosts",
    "update:elementProps",
  ],
  setup(props, { emit }) {
    const selectedSocial = ref(null);
    const showDescModal = ref(false);
    const numPosts = ref(3);
    //const posts = reactive([]);
    const DescModalTitle = ref("");
    const isGenerating = ref(false);
    const confirmedPost = ref(null);
    const GenerateButtonText = computed(() =>
      isGenerating.value ? "Generazione in corso ..." : "Genera con IA ..."
    );

    //const localElement = ref({ editor: props.editor, posts: [] });
    const localElement = reactive(JSON.parse(JSON.stringify(props.element)));

    const EditorTitle = computed(
      () => `${localElement.editor.name} - ${localElement.editor.discriminator}`
    );

    const emitElementUpdate = () => {
      emit("update:elementProps", localElement.props);
    };

    const selectedPosts = computed(() => {
      return localElement?.posts.filter((post) => post.selected);
    });

    watch(
      selectedPosts,
      (newSelectedPosts) => {
        emit("update:selectedPosts", newSelectedPosts);
      },
      { deep: true }
    );

    const generateEmptyPosts = (amount) => {
      localElement.posts.push(
        ...Array.from({ length: amount }, () => ({
          cid: generateRandomId(), // ID univoco
          text: null, // null = spinner, string = text
          image: false, // false = spinner, null = no image, string = image
          selected: false,
          confirmed: false,
          comment: null,
        }))
      );
    };

    const generateContent = async (option = null, description = null) => {
      const amount = numPosts.value;
      //console.log(amount, option, description); // Debugging

      // Pre-validation
      if (amount === 0) {
        console.warn("Cannot generate content for 0 posts.");
        return;
      }
      if (amount > 9) {
        console.warn("Cannot generate content for more than 9 posts.");
        return;
      }
      // Validare "description", se c'è option

      isGenerating.value = true;
      generateEmptyPosts(amount);

      // compilazione blocchi post (vuoti o con IA)
      const newPosts = localElement.posts.slice(-amount);
      const lcOption = option?.toLowerCase();
      switch (lcOption) {
        case "post": {
          newPosts.forEach((post) => {
            post.image = null;
          });
          await generatePosts(newPosts, description);
          break;
        }
        case "image": {
          newPosts.forEach((post) => {
            post.text = ``;
          });
          await generateImages(newPosts, description);
          break;
        }
        case "both": {
          //const promise_posts = generatePosts(newPosts, description);
          //const promise_images = generateImages(newPosts, description);
          //await Promise.all([promise_posts, promise_images]);
          await generatePostsAndImages(newPosts, description);
          break;
        }
        default: {
          //console.warn(`Option "${option}" is not recognized.`);
          newPosts.forEach((post) => {
            post.text = ``;
            post.image = null;
          });
          break;
        }
      }

      isGenerating.value = false;
      validateEditor();
    };

    const generateImages = async (newPosts, description) => {
      const promises = newPosts.map((post, index) =>
        generateImage(post, description, index)
      );
      await Promise.all(promises);
    };

    const generateImage = async (post, description, index = -1) => {
      const pos = index >= 0 ? index + 1 : -1; // solo per logging. -1 = non specificato
      try {
        const response = await axios.post(ApiEndPoints.AI + "/createimage", {
          prompt: description,
          n: 1,
        });
        post.image = `data:image/png;base64,${response.data.data[0].b64_json}`;
      } catch (error) {
        console.error(`Error during API call for image ${pos}:`, error);
      }
    };

    const generatePosts = async (newPosts, description) => {
      const promises = newPosts.map((post, index) =>
        generatePost(post, description, index)
      );
      await Promise.all(promises);
    };

    const generatePost = async (post, description, index = -1) => {
      const pos = index >= 0 ? index + 1 : -1; // solo per logging. -1 = non specificato
      try {
        const response = await axios.post(ApiEndPoints.AI + "/createcontent", {
          socialName: localElement.editor.name,
          description: description,
          maxLen: 349,
        });
        post.text = response.data.content;
      } catch (error) {
        console.error(`Error during API call for post: ${pos}`, error);
      }
    };

    const generatePostsAndImages = async (newPosts, description) => {
      const promises = newPosts.map((post, index) =>
        generatePost(post, description, index).then(() => {
          if (localElement.posts.includes(post)) {
            return generateImage(post, post.text, index);
          }
        })
      );
      await Promise.all(promises);
    };

    const removeElement = () => {
      if (hasValidPosts()) {
        if (confirm(`Sei sicuro di voler rimuovere ${EditorTitle.value}?`)) {
          emit("element-removed", localElement.cid);
        }
      } else {
        emit("element-removed", localElement.cid);
      }
    };

    const hasValidPosts = () => {
      return localElement.posts.some((post) => post.text || post.image);
    };

    const ValidateEditor = computed(() => {
      return confirmedPost.value !== null && confirmedPost.value.text !== null;
    });

    const removePost = (index) => {
      const p = localElement.posts[index];
      if (
        (!p.text && !p.image) ||
        confirm("Sei sicuro di voler rimuovere questo post?")
      ) {
        if (confirmedPost.value?.cid === p.cid) {
          confirmedPost.value = null;
        }
        emit("post-removed", p.cid);
        localElement.posts.splice(index, 1);

        validateEditor();
      }
    };

    const toggleSelectPost = (index) => {
      localElement.posts[index].selected = !localElement.posts[index].selected;
      validateEditor();
    };

    const confirmPost = (index) => {
      const backupPost = ref(null); // qui memorizzo eventuali valori da conservare prima di sovrascriverli
      if (confirmedPost.value) {
        if (confirmedPost.value.cid === localElement.posts[index].cid) {
          // la selezione corrisponde all'attuale post selezionato?
          // Avviso che De-Confermando perdo i dati custom.
          if (
            !confirm(
              "Annullando la conferma perderai la configurazione personalizzata dell'attuale post selezionato. Continuare?"
            )
          )
            return;
        } else {
          // Cambio post, ma devo conservare i dati custom
          backupPost.value = JSON.parse(JSON.stringify(confirmedPost.value));
        }
      }

      localElement.posts.forEach((post, i) => {
        if (i === index) {
          if (post.confirmed) {
            // De-conferma
            post.confirmed = false;
            confirmedPost.value = null;
          } else {
            // Conferma
            post.selected = true;
            post.confirmed = true;
            confirmedPost.value = JSON.parse(JSON.stringify(post));
            populateConfirmedPostValues(confirmedPost.value, backupPost?.value);

            console.log(confirmedPost.value);
            emit("post-confirmed", confirmedPost.value);
          }
        } else {
          post.confirmed = false;
        }
      });
      validateEditor();
    };

    const populateConfirmedPostValues = (post, backup = null) => {
      post.editor = localElement.editor;
      post.editorCid = localElement.cid;
      post.target = backup ? backup.target : { gender: ["all"], age: ["all"] };
      post.budget = backup ? backup.budget : null;
      // dati fuffi e non
      //post.ghost = backup ? backup.ghost : localElement.ghost;
      //post.onlinePostId = backup ? backup.onlinePostId : null;
      post.priceType = backup
        ? backup.priceType
        : localElement.editor.priceType;
      post.price = backup ? backup.price : localElement.editor.price;
      post.organicCoveragePrice = backup
        ? backup.organicCoveragePrice
        : localElement.editor.organicCoveragePrice;
      post.sponsoredCoveragePrice = backup
        ? backup.sponsoredCoveragePrice
        : localElement.editor.sponsoredCoveragePrice;
    };

    const validateEditor = () => {
      const isValid = localElement.posts.some(
        (post) => post.confirmed && post.text
      );
      emit("validation-changed", isValid);
    };

    const openModal = (modalType) => {
      closeModals();
      const lcModalType = modalType.toLowerCase();
      switch (lcModalType) {
        case "postdescription":
          DescModalTitle.value = "Descrivi il contenuto da generare";
          showDescModal.value = true;
          break;
        default:
          console.warn(`Modal type "${modalType}" is not recognized.`);
      }
    };

    const closeModals = () => {
      showDescModal.value = false;
    };

    const handleModalSubmitted = (result) => {
      closeModals();
      generateContent(result.option, result.description);
    };

    return {
      selectedSocial,
      numPosts,
      generateContent,
      removeElement,
      removePost,
      toggleSelectPost,
      confirmPost,
      showDescModal,
      openModal,
      closeModals,
      DescModalTitle,
      handleModalSubmitted,
      isGenerating,
      GenerateButtonText,
      EditorTitle,
      confirmedPost,
      ValidateEditor,
      localElement,
      emitElementUpdate,
    };
  },
};
</script>

<style scoped>
/* titolo */
.contentwave_editor_title {
  font-family: "Bebas Neue", cursive !important;
  font-weight: bold !important;
  color: #15465c !important;
  margin-top: 0px !important;
  margin-bottom: 10px;
}

/* ghost */
.contentwave_post_specs_ghost {
  border-top: 1px solid #15465c;
  padding-top: 16px;
  padding-bottom: 16px;
}
.contentwave_post_specs_ghost > label {
  font-family: "Bebas Neue", cursive;
  font-weight: 500;
}
.contentwave_post_specs_ghost_postid {
  padding-bottom: 16px;
}
.contentwave_post_specs_ghost_postid > label {
  font-family: "Bebas Neue", cursive;
  font-weight: 500;
}
.contentwave_post_specs_ghost_postid > input {
  background-color: #f2f2f2;
  font-size: 14px;
  line-height: 20px;
  height: 25px;
  font-family: inherit;
  position: relative;
  border: none !important;
  margin-left: 10px !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

/* hashtags */
.contentwave_post_specs_hashtags {
  border-top: 1px solid #15465c;
  padding-top: 16px;
  padding-bottom: 16px;
}
.contentwave_post_specs_hashtags > label {
  font-family: "Bebas Neue", cursive;
  font-weight: 500;
}
.contentwave_post_specs_hashtags > input {
  background-color: #f2f2f2;
  font-size: 14px;
  line-height: 20px;
  height: 25px;
  font-family: inherit;
  position: relative;
  border: none !important;
  margin-left: 10px !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  width: calc(100% - 76px);
}

/* page link */
.contentwave_post_specs_pakelink {
  border-top: 1px solid #15465c;
  padding-top: 16px;
  padding-bottom: 16px;
}
.contentwave_post_specs_pakelink > label {
  font-family: "Bebas Neue", cursive;
  font-weight: 500;
}
.contentwave_post_specs_pakelink > input {
  background-color: #f2f2f2;
  font-size: 14px;
  line-height: 20px;
  height: 25px;
  font-family: inherit;
  position: relative;
  border: none !important;
  margin-left: 10px !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  width: calc(100% - 76px);
}

/* contenitore aggiusta posts */
.contentwave_post_specs_addnposts {
  border-top: 1px solid #15465c;
  padding-top: 16px;
}

/* pulsante rimuovere editore */
.contentwave_editor_rimuovieditore {
  background: red !important;
  color: white !important;
  border: none !important;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px !important;
  margin-bottom: 20px;
}
.contentwave_editor_rimuovieditore:hover {
  color: black !important;
}

/* aggiungi n post */
.contentwave_editor_title_addpost {
  font-family: "Bebas Neue", cursive;
  font-weight: 500;
}
.contentwave_editor_title_addnpost {
  margin-left: 10px;
  background: transparent;
  border: 1px solid rgba(44, 190, 255, 0.36078);
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  height: 15px !important;
}

/* pulsanti generazione n post editor */
.contentwave_editor_button_createpost {
  margin-top: 10px;
  margin-bottom: 10px;
  background: #2cbeff !important;
  color: white !important;
  border: none !important;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px !important;
}
.contentwave_editor_button_createpost:hover {
  color: black !important;
}
.contentwave_editor_button_createpost_vuoto {
  margin-left: 10px;
}

/* blocco post contentwave editore */
.post-block {
  border-top: 1px solid #15465c;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* textarea del post */
.contentwave_editor_post_testopost {
  width: 100%;
  min-height: 10em;
  box-sizing: border-box;
  resize: none;
  overflow-y: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  margin-bottom: 10px;
}
.contentwave_editor_post_testopost::-webkit-scrollbar {
  width: 10px;
}
.contentwave_editor_post_testopost::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.contentwave_editor_post_testopost::-webkit-scrollbar-thumb {
  background-color: #2cbeff;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

/* commento del post */
.contentwave_editor_post_commentopost {
  width: 100%;
  min-height: 5em;
  box-sizing: border-box;
  resize: none;
  overflow-y: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  margin-bottom: 10px;
}
.contentwave_editor_post_commentopost::-webkit-scrollbar {
  width: 10px;
}
.contentwave_editor_post_commentopost::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.contentwave_editor_post_commentopost::-webkit-scrollbar-thumb {
  background-color: #2cbeff;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

/* rimuovi post */
.contentwave_editor_button_removepost {
  background: red !important;
  color: white !important;
  border: none !important;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px !important;
}
.contentwave_editor_button_removepost:hover {
  color: black !important;
}
/* seleziona post */
.contentwave_editor_button_selectpost {
  background: #2cbeff !important;
  color: white !important;
  border: none !important;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px !important;
  margin-left: 10px;
}
.contentwave_editor_button_selectpost:hover {
  color: black !important;
}

/* conferma post */
.contentwave_editor_button_confirm {
  background: #28a745 !important;
  color: white !important;
  border: none !important;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px !important;
  margin-left: 10px;
}
.contentwave_editor_button_confirm:hover {
  color: black !important;
}

.post-block.selected {
  background-color: #fffbe0; /* Giallino leggero */
}

.post-block.confirmed {
  background-color: #b8f8c7 !important; /* Bordo verde per i post confermati */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
